import Highcharts from "highcharts";
import PatternFill from "highcharts/modules/pattern-fill";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { GridLayout } from "../../../layouts";
import { AppInsightService } from "../../../services";
import QueueDetails from "./QueueDetails/QueueDetails";
import DetailPlan from "./DetailPlan/DetailPlan";
import OutPatientPlanResult from "./Results/OutpatientPlanResult";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import Demand from "./Demand/Demand";
import Phasing from "./Phasing/Phasing";
import { useLocation } from "react-router-dom";

PatternFill(Highcharts);

const OutPatientPlan = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "OutPatientPlan" });
  }, []);

  const { t: translate, i18n } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    if (i18n.language === "sv") {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ",",
        },
      });
    } else {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
        },
      });
    }
  }, [i18n]);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    location.state && location.state.tab ? location.state.tab : 0
  );
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState<boolean>(false);
  const [isResetButtonClicked, setIsResetButtonClicked] = useState<boolean>(false);
  const [isTabBarClicked, setIsTabBarClicked] = useState<boolean>(false);
  const [clickedTabIndex, setClickedTabIndex] = useState<number>(0);

  const [isOutpatientParametersModifying, setIsOutpatientParametersModifying] = useState<boolean>(false);
  const [nextAvailableTabIndex, setNextAvailableTabIndex] = useState<number>(0);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.MultipleQueueCuttingPlans);
  const isDetailPlanFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.DetailedPlanMultiSelect);

  const tabs = [
    translate("demand"),
    translate("Phasing"),
    translate("detail_plan"),
    translate("referral_queue"),
    translate("Result"),
  ];

  const tabsWithoutDetailPlan = [
    translate("demand"),
    translate("Phasing"),
    translate("referral_queue"),
    translate("Result"),
  ];

  const handleTabBarClick = (nextIndex: number) => {
    setClickedTabIndex(nextIndex);
    setIsTabBarClicked(true);
  };

  const handleSaveButtonClick = () => {
    setIsSaveButtonClicked(true);
  };

  const handleResetButtonClick = () => {
    if (isDetailPlanFeatureFlagEnabled && (selectedTabIndex === 3 || selectedTabIndex === 2)) {
      setIsResetButtonClicked(true);
    } else if (!isDetailPlanFeatureFlagEnabled && selectedTabIndex === 2) {
      setIsResetButtonClicked(true);
    }
  };

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary
            tabs={isDetailPlanFeatureFlagEnabled ? tabs : tabsWithoutDetailPlan}
            onClick={handleTabBarClick}
            index={selectedTabIndex}
          />

          <div className="flex items-center justify-between">
            {(isDetailPlanFeatureFlagEnabled &&
              isFeatureFlagEnabled &&
              (selectedTabIndex === 2 || selectedTabIndex === 3)) ||
            (!isDetailPlanFeatureFlagEnabled && isFeatureFlagEnabled && selectedTabIndex == 2) ? (
              <div className="mx-2 my-2">
                <Button
                  text={translate("reset")}
                  variant={"secondary"}
                  additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                  onClick={handleResetButtonClick}
                  disabled={isOutpatientParametersModifying}
                />
              </div>
            ) : null}

            {(isDetailPlanFeatureFlagEnabled && selectedTabIndex != 4) ||
            (!isDetailPlanFeatureFlagEnabled && selectedTabIndex != 3) ? (
              <div className="my-2">
                <Button
                  text={translate("save_changes")}
                  variant={"primary"}
                  additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                  onClick={handleSaveButtonClick}
                  disabled={isOutpatientParametersModifying}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-0 bg-[#F8F8F8] px-2 pt-4 lg:h-[78vh] xl:h-[78vh]">
          <div className="flex h-full flex-row" id="wraper">
            {selectedTabIndex === 0 ? (
              <Demand
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                isOutpatientParametersModifying={isOutpatientParametersModifying}
                setIsOutpatientParametersModifying={setIsOutpatientParametersModifying}
                isSaveButtonClicked={isSaveButtonClicked}
                setIsSaveButtonClicked={setIsSaveButtonClicked}
                nextAvailableTabIndex={nextAvailableTabIndex}
                setNextAvailableTabIndex={setNextAvailableTabIndex}
                isTabBarClicked={isTabBarClicked}
                setIsTabBarClicked={setIsTabBarClicked}
                clickedTabIndex={clickedTabIndex}
              />
            ) : selectedTabIndex === 1 ? (
              <Phasing
                selectedTabIndex={selectedTabIndex}
                isSaveButtonClicked={isSaveButtonClicked}
                setIsSaveButtonClicked={setIsSaveButtonClicked}
                setNextAvailableTabIndex={setNextAvailableTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                nextAvailableTabIndex={nextAvailableTabIndex}
                isTabBarClicked={isTabBarClicked}
                setIsTabBarClicked={setIsTabBarClicked}
                clickedTabIndex={clickedTabIndex}
                isOutpatientParametersModifying={isOutpatientParametersModifying}
                setIsOutpatientParametersModifying={setIsOutpatientParametersModifying}
              />
            ) : selectedTabIndex === 2 && isDetailPlanFeatureFlagEnabled ? (
              <DetailPlan
                selectedTabIndex={selectedTabIndex}
                nextAvailableTabIndex={nextAvailableTabIndex}
                setNextAvailableTabIndex={setNextAvailableTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                isSaveButtonClicked={isSaveButtonClicked}
                setIsSaveButtonClicked={setIsSaveButtonClicked}
                isResetButtonClicked={isResetButtonClicked}
                setIsResetButtonClicked={setIsResetButtonClicked}
                isTabBarClicked={isTabBarClicked}
                setIsTabBarClicked={setIsTabBarClicked}
                clickedTabIndex={clickedTabIndex}
                isOutpatientParametersModifying={isOutpatientParametersModifying}
                setIsOutpatientParametersModifying={setIsOutpatientParametersModifying}
              />
            ) : (isDetailPlanFeatureFlagEnabled ? selectedTabIndex === 3 : selectedTabIndex === 2) ? (
              <QueueDetails
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                isSaveButtonClicked={isSaveButtonClicked}
                setIsSaveButtonClicked={setIsSaveButtonClicked}
                isOutpatientParametersModifying={isOutpatientParametersModifying}
                setIsOutpatientParametersModifying={setIsOutpatientParametersModifying}
                nextAvailableTabIndex={nextAvailableTabIndex}
                isResetButtonClicked={isResetButtonClicked}
                setIsResetButtonClicked={setIsResetButtonClicked}
                setNextAvailableTabIndex={setNextAvailableTabIndex}
                isTabBarClicked={isTabBarClicked}
                setIsTabBarClicked={setIsTabBarClicked}
                clickedTabIndex={clickedTabIndex}
              />
            ) : (isDetailPlanFeatureFlagEnabled ? selectedTabIndex === 4 : selectedTabIndex === 3) ? (
              <OutPatientPlanResult
                setSelectedTabIndex={setSelectedTabIndex}
                isTabBarClicked={isTabBarClicked}
                setIsTabBarClicked={setIsTabBarClicked}
                clickedTabIndex={clickedTabIndex}
              />
            ) : null}
          </div>
        </div>
      </div>
    </GridLayout>
  );
};

export default OutPatientPlan;
