import React, { ReactNode } from "react";

interface GridContainerProps {
  children: ReactNode;
  additionalStyles?: string;
}

const GridContainer = ({ children, additionalStyles }: GridContainerProps) => {
  return (
    <div className={`grid grid-cols-12 gap-x-[1.406rem] lg:px-[0.5rem] xl:px-[1.406rem] ${additionalStyles}`}>
      {children}
    </div>
  );
};

export default GridContainer;
