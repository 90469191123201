import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import OutpatientMultiselectFilter from "../../Components/OutpatientMultiselectFilter/OutpatientMultiselectFilter";
import { OutPatientMultiSelectRequest } from "../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { OutpatientPlanResultRequest } from "../../../../interfaces/production-plan/outpatient/outpatientPlanResultRequest";
import { OutpatientPlanService } from "../../../../services";
import { OutpatientResults } from "../../../../interfaces/production-plan/outpatient/outpatientResults";
import ResultsChartV2 from "./ResultsChart/ResultChartV2";
import { OutpatientResultsKPI } from "../../../../interfaces/production-plan/outpatient/outpatientResultsKPI";
import OutPatientResultPanelV2 from "./ResultsPanel/OutpatientPlanResultPanelV2";
import { useTranslation } from "react-i18next";
import { OutPatientResultWeekData, OutpatientResultByPlanningObjectResponse } from "../../../../interfaces/responses/outpatientResultByPlanningObjectResponse";

export interface ResultProps {
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  isTabBarClicked: boolean;
  setIsTabBarClicked: Dispatch<SetStateAction<boolean>>;
  clickedTabIndex: number;
}

const OutPatientPlanResult = ({
  setSelectedTabIndex,
  isTabBarClicked,
  setIsTabBarClicked,
  clickedTabIndex,
}: ResultProps) => {
  const [outPatientMultiSelectRequest, setOutPatientMultiSelectRequest] = useState<OutPatientMultiSelectRequest | null>(
    null
  );

  const { t: translate, i18n } = useTranslation();
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.OutpatientResultMultiSelect);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenarioId = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const [outpatientResult, setOutpatientResult] = useState<OutpatientResults>({
    incomingVisits: [],
    queueCuttingVisits: [],
    getLastYearOutpatientResuls: [],
    chartDataSeries: [],
    isDataLoaded: false,
    isFilterByPlanningObject: false
  });

  const [outpatientResultKPI, setOutpatientResultKPI] = useState<OutpatientResultsKPI>({
    productionPlan: 0,
    queueCuttingPlan: 0,
    overloadPlanAverage: 0,
  });

  useEffect(() => {
    if (isTabBarClicked) {
      setSelectedTabIndex(() => clickedTabIndex);
      setIsTabBarClicked(false);
    }
  }, [isTabBarClicked]);

  useEffect(() => {
    if (careUnitId && scenarioId && year) {
      fetchOutpatientPlanData(outPatientMultiSelectRequest);
    }
  }, [careUnitId, scenarioId, year, outPatientMultiSelectRequest]);

  const fetchOutpatientPlanData = async (outPatientMultiSelectParams: OutPatientMultiSelectRequest | null) => {
    if (
      careUnitId &&
      careUnitId > 0 &&
      outPatientMultiSelectParams &&
      outPatientMultiSelectParams.professionIds.length > 0
    ) {
      const chartData: OutpatientResults = {
        incomingVisits: [],
        queueCuttingVisits: [],
        getLastYearOutpatientResuls: [],
        chartDataSeries: [],
        isDataLoaded: false,
        isFilterByPlanningObject: outPatientMultiSelectRequest?.isPlanningObjectFilterEnabled ?? false
      };
      setOutpatientResult(chartData);

      const outpatientResultRequest: OutpatientPlanResultRequest = {
        careUnitId: careUnitId,
        productionYear: year,
        professionIds:
          outPatientMultiSelectParams?.professionIds[0] === -1 ? [] : outPatientMultiSelectParams?.professionIds,
        contactTypeIds:
          outPatientMultiSelectParams?.contactTypeIds[0] === -1 ? [] : outPatientMultiSelectParams?.contactTypeIds,
        contactReasonGroupingIds:
          outPatientMultiSelectParams?.contactReasonGroupingIds[0] === -1
            ? []
            : outPatientMultiSelectParams?.contactReasonGroupingIds,
        typesOfVisit:
          outPatientMultiSelectParams?.typesOfVisit[0] === -1 ? [] : outPatientMultiSelectParams?.typesOfVisit,
        careTypeIds: outPatientMultiSelectParams?.careTypeIds[0] === -1 ? [] : outPatientMultiSelectParams?.careTypeIds,
        typesOfEmergency:
          outPatientMultiSelectParams?.typesOfEmergency[0] === -1 ? [] : outPatientMultiSelectParams?.typesOfEmergency,
        medicalSpecialtyIds:
          outPatientMultiSelectParams?.medicalSpecialtyIds[0] === -1
            ? []
            : outPatientMultiSelectParams?.medicalSpecialtyIds,
        scenarioId: scenarioId,
        isFilterByPlanningObject: outPatientMultiSelectRequest?.isPlanningObjectFilterEnabled

      };

      await OutpatientPlanService.getOutpatientResults(outpatientResultRequest).then((res) => {

        let productionPlanByPlanningObject = [];

        productionPlanByPlanningObject = res.resultsByPlanningObject.map((pp : OutpatientResultByPlanningObjectResponse) => {
          return {
            name: translate(pp.planningObjectName),
            data: pp.weeksData.map((r : OutPatientResultWeekData) => r.productionPlan),
            type: "column",
          };
        });

        const chartData: OutpatientResults = {
          incomingVisits: res.incomingVisits,
          queueCuttingVisits: res.queueCuttingVisits,
          getLastYearOutpatientResuls: res.getLastYearOutpatientResuls,
          isDataLoaded: true,
          chartDataSeries: productionPlanByPlanningObject,
          isFilterByPlanningObject: outPatientMultiSelectRequest?.isPlanningObjectFilterEnabled ?? false
        };

        const kpis: OutpatientResultsKPI = {
          productionPlan: res.productionPlan,
          queueCuttingPlan: res.queueCuttingPlan,
          overloadPlanAverage: res.overloadPlanAverage,
        };

        setOutpatientResult(chartData);
        setOutpatientResultKPI(kpis);
      });
    }
  };

  return (
    <>
      <div className="h-full w-5/6">
        <ResultsChartV2 outpatientResults={outpatientResult} />
      </div>
      <div className="ml-5 w-1/6">
        <OutPatientResultPanelV2 outpatientResultsKPI={outpatientResultKPI} />
        {isFeatureFlagEnabled && (
          <OutpatientMultiselectFilter
            setOutPatientMultiSelectRequest={setOutPatientMultiSelectRequest}
            outPatientMultiSelectRequest={outPatientMultiSelectRequest}
          />
        )}
      </div>
    </>
  );
};

export default OutPatientPlanResult;
