import { CellChange, ChangeSource } from "handsontable/common";
import { HotTableClass as HotTable } from "@handsontable/react";
import { useTranslation } from "react-i18next";
import React, { Dispatch } from "react";

import { ActivityAnalysisShiftSchema } from "../../../../interfaces/analytics/activity-analysis-shift-schema";
import { UseAfterChangeReturnType } from "../../../../interfaces/analytics/handsontable/use-afterchange-return-type";
import { ActivityAnalysisIsEditedRefType } from "../../../../interfaces/analytics/activity-analysis-is-edited-ref-type";

const useAfterChange = (
  data: ActivityAnalysisShiftSchema[],
  dataTable: React.RefObject<HotTable | null>,
  setData: React.Dispatch<React.SetStateAction<ActivityAnalysisShiftSchema[]>>,
  isWithinShifts: boolean,
  isEditedRef: React.MutableRefObject<ActivityAnalysisIsEditedRefType>,
  setIsTableChanged: Dispatch<React.SetStateAction<boolean>>
): UseAfterChangeReturnType => {
  const { t: translate } = useTranslation();

  const afterChange = (changes: CellChange[] | null, source: ChangeSource) => {
    if (source === "edit" || source === "CopyPaste.paste") {
      const isTotalRowExists = data.find((row) => row.id === "total_row");

      const noOfRows = data.length;

      if (data) {
        if (!isTotalRowExists) {
          const newRow = {
            id: "total_row",
            activity: translate("total"),
            competenceGroupLvl2: "",
            timePerActivity: null,
            activitiesPerWeek: null,
            numberPresence: null,
            weeksPerYear: null,
            timeSpent: `=SUM(G1:G${noOfRows})`,
            weeklyWorkingHours: null,
            fte: `=SUM(I1:I${noOfRows})`,
          } as ActivityAnalysisShiftSchema;

          setData([...data, newRow]);
        }
      }
    }

    if (source === "edit" || source === "Autofill.fill" || source === "CopyPaste.paste") {
      dataTable.current?.hotInstance?.validateCells((valid) => {
        if (valid && changes) {
          const [, , prevValue, value] = changes[0];
          if (prevValue !== value) {
            if (isWithinShifts) {
              isEditedRef.current = {
                ...isEditedRef.current,
                withinShift: true,
              };
            } else {
              isEditedRef.current = {
                ...isEditedRef.current,
                outsideShift: true,
              };
            }
          }
        }

        if (changes) {
          setIsTableChanged(true);
        }
      });
    }
  };

  return { afterChange };
};

export default useAfterChange;
