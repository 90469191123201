import React from "react";
import { IconButtonProps } from "../../interfaces/props";
import SettingIcon from "../icons/SettingIcon";
import "./SettingIconButton.css";

const SettingIconButton = ({ onClick, disabled }: IconButtonProps) => {
  return (
    <button
      className={disabled ? "setting-icon-button--disabled" : "setting-icon-button"}
      onClick={onClick}
      disabled={disabled}
    >
      <SettingIcon width={16} height={16} />
    </button>
  );
};

export default SettingIconButton;
