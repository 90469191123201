import React, { useState } from "react";
import QueueDetailsChart from "./QueueDetailsChart/QueueDetailsChart";
import QueueDetailsSidePanelWithMultiSelect from "./QueueDetailsSidePanel/QueueDetailsSidePanelWithMultiSelect";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import QueueDetailsSidePanel from "./QueueDetailsSidePanel/QueueDetailsSidePanel";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { queueDetailsProps } from "../../../../interfaces/props/queueDetailsProps";
import { OutPatientMultiSelectRequest } from "../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";

const QueueDetails = ({
  selectedTabIndex,
  setSelectedTabIndex,
  isSaveButtonClicked,
  setIsSaveButtonClicked,
  nextAvailableTabIndex,
  isResetButtonClicked,
  setIsResetButtonClicked,
  setNextAvailableTabIndex,
  isOutpatientParametersModifying,
  setIsOutpatientParametersModifying,
  isTabBarClicked,
  setIsTabBarClicked,
  clickedTabIndex,
}: queueDetailsProps) => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.QueueDetailsMultiSelect);

  const [queueDetailRequest, setQueueDetailRequest] = useState<OutPatientMultiSelectRequest | null>(null);

  const [isQueueForecastVisible, setIsQueueForecastVisible] = useState(false);
  const [isQueueCuttingPlanUpdated, setIsQueueCuttingPlanUpdated] = useState(0);

  return (
    <>
      <div className="h-full w-5/6">
        <QueueDetailsChart
          queueDetailsRequest={queueDetailRequest}
          isQueueForecastVisible={isQueueForecastVisible}
          isQueueCuttingPlanUpdated={isQueueCuttingPlanUpdated}
        />
      </div>
      <div className="ml-5 w-1/6">
        {isFeatureFlagEnabled ? (
          <QueueDetailsSidePanelWithMultiSelect
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            queueDetailRequest={queueDetailRequest}
            setQueueDetailRequest={setQueueDetailRequest}
            isSaveButtonClicked={isSaveButtonClicked}
            setIsSaveButtonClicked={setIsSaveButtonClicked}
            isOutpatientParametersModifying={isOutpatientParametersModifying}
            setIsOutpatientParametersModifying={setIsOutpatientParametersModifying}
            nextAvailableTabIndex={nextAvailableTabIndex}
            isResetButtonClicked={isResetButtonClicked}
            setIsResetButtonClicked={setIsResetButtonClicked}
            setNextAvailableTabIndex={setNextAvailableTabIndex}
            isTabBarClicked={isTabBarClicked}
            setIsTabBarClicked={setIsTabBarClicked}
            clickedTabIndex={clickedTabIndex}
            isQueueForecastVisible={isQueueForecastVisible}
            setIsQueueForecastVisible={setIsQueueForecastVisible}
            setIsQueueCuttingPlanUpdated={setIsQueueCuttingPlanUpdated}
          />
        ) : (
          <QueueDetailsSidePanel
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            isOutpatientParametersModifying={isOutpatientParametersModifying}
            setIsOutpatientParametersModifying={setIsOutpatientParametersModifying}
            setNextAvailableTabIndex={setNextAvailableTabIndex}
            nextAvailableTabIndex={nextAvailableTabIndex}
            isSaveButtonClicked={isSaveButtonClicked}
            setIsSaveButtonClicked={setIsSaveButtonClicked}
            isTabBarClicked={isTabBarClicked}
            setIsTabBarClicked={setIsTabBarClicked}
            clickedTabIndex={clickedTabIndex}
          />
        )}
      </div>
    </>
  );
};

export default QueueDetails;
