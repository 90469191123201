import React, { useEffect, useState } from "react";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";

const DynamicReports = () => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.Metabase);
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    if (isFeatureFlagEnabled) {
      setShowIframe(true);
    }
  }, [isFeatureFlagEnabled]);

  return (
    <>
      {showIframe && (
        <iframe
          title="AvgCareTime"
          style={{ width: "95vw", height: "90vh", border: "none" }}
          src="https://app.powerbi.com/reportEmbed?reportId=d61f03fb-4df5-416b-99e9-6f724bae5bfd&autoAuth=true&embeddedDemo=true"
          allowFullScreen={true}
        ></iframe>
      )}
    </>
  );
};

export default DynamicReports;
