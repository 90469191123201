import { useTranslation } from "react-i18next";
import { ActivityAnalysisResultsType } from "../../../../../interfaces/analytics/activity-analysis-results";
import useGetUniqueWorkComptences from "./useGetUniqueWorkComptences";
import { ProdType } from "../../../../../interfaces/productivity/workShifts/prodType";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";

const useGetResultsPivotData = (tableData: ActivityAnalysisResultsType[], productionTypes: ProdType[]): string[][] => {
  const { t: translate } = useTranslation();

  const data: string[][] = [];

  const workCompetences = tableData.map((result: ActivityAnalysisResultsType) => {
    return { id: result.workCompetenceId, name: result.workCompetenceName };
  });

  const competences = useGetUniqueWorkComptences(workCompetences);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.DynamicProdTypes);

  const productionTypesNames = productionTypes
    .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
    .map((prodType) => prodType.name);

  const staticProductionTypeNames = [
    "ProductionShareConsultant",
    "ProductionShareManagement",
    "ProductionShareNonClinicalTime",
    "ProductionShareOutpatientVisit",
    "ProductionShareStExternalTraining",
  ];

  const productionTypesOrder: string[] = [
    "activity_production_plan",
    "activities_within_shift",
    "other_activities",
    "activity_result_mapping",
    ...(isFeatureFlagEnabled ? productionTypesNames : staticProductionTypeNames),
    "result_capacity_plan",
  ];

  for (const category of productionTypesOrder) {
    const prodType = tableData.map((x) => x.productionType).indexOf(category) !== -1;

    if (prodType) {
      const rowData: string[] = [];
      rowData.push(translate(category));

      for (const competence of competences) {
        const filteredHours =
          category === "result_capacity_plan"
            ? Math.round(
                tableData
                  .filter(
                    (x) =>
                      (isFeatureFlagEnabled
                        ? productionTypesNames.includes(x.productionType)
                        : staticProductionTypeNames.includes(x.productionType)) &&
                      x.workCompetenceName === competence.name &&
                      x.workCompetenceId === competence.id
                  )
                  .map((x) => Math.round(x.hours ?? 0))
                  .reduce((a, b) => a + b, 0)
              ).toString()
            : tableData
                .filter(
                  (x) =>
                    x.productionType === category &&
                    x.workCompetenceName === competence.name &&
                    x.workCompetenceId === competence.id
                )
                .map((x) => Math.round(x.hours ?? 0))
                .reduce((a, b) => a + b, 0)
                .toString();

        rowData.push(filteredHours || "0");

        const filteredFte =
          category === "result_capacity_plan"
            ? tableData
                .filter(
                  (x) =>
                    (isFeatureFlagEnabled
                      ? productionTypesNames.includes(x.productionType)
                      : staticProductionTypeNames.includes(x.productionType)) &&
                    x.workCompetenceName === competence.name &&
                    x.workCompetenceId === competence.id
                )
                .map((x) => Math.round(x.fte ?? 0)) // Added Math.round
                .reduce((a, b) => a + b, 0)
                .toString()
            : tableData
                .filter(
                  (x) =>
                    x.productionType === category &&
                    x.workCompetenceName === competence.name &&
                    x.workCompetenceId === competence.id
                )
                .map((x) => Math.round(x.fte ?? 0)) // Added Math.round
                .reduce((a, b) => a + b, 0)
                .toString();

        rowData.push(filteredFte || "0");
      }

      data.push(rowData);
    }
  }

  return data;
};

export default useGetResultsPivotData;
