import { AxiosResponse } from "axios";
import { ProdType } from "../interfaces/productivity/workShifts/prodType";
import HttpCommonService from "./httpCommonService";
import { prodTypeAllocationBySetting } from "../interfaces/productivity/benchmarking/prodTypeAllocationBySetting";

const ProdTypeService = {
  getProductionTypesByCareType: async (careUnitTypeId: number): Promise<AxiosResponse<ProdType[]>> => {
    return await HttpCommonService.getAsync(`api/prodTypes/careUnitType/${careUnitTypeId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getProductionTypeAllocationBySetting: async (
    careUnitTypeId: number,
    settingTypeId: number
  ): Promise<AxiosResponse<prodTypeAllocationBySetting>> => {
    return await HttpCommonService.getAsync(
      `api/prodTypes/setting/careUnitType/${careUnitTypeId}/settingType/${settingTypeId}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default ProdTypeService;
