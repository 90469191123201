import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HighchartsReact } from "highcharts-react-official";
import { v4 as uuid } from "uuid";

import {
  ChartDataObject,
  ChartDataResponse,
  BenchmarkDataObj,
  ChartData,
} from "../../../../interfaces/productivity/benchmarking-chart-types";
import { RootState } from "../../../../redux/store";
import ResultService from "../../../../services/resultService";
import { BenchmarkingChartProps } from "../../../../interfaces/props";
import { useMediaQuery } from "../../../../hooks";
import { SCREEN_SIZES } from "../../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../../utils/highcharts/common";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { CareUnitTypeEnum } from "../../../../utils/constants/careUnitTypes";
import { useNumberFormat } from "../../../../hooks/use-number-format";

const BenchmarkingChart = ({
  dateRange,
  selectedInput,
  selectedCompetenceInput,
  selectedCareLevel,
  selectedMedicalSpeciality,
  selectedCompetenceType,
  selectedContactType,
  selectedProductionTypes,
  afterFetchingChartData,
  endDateErrors,
  setErrorNotification,
  selectedProdTypeIdList,
}: BenchmarkingChartProps) => {
  const { t: translate, i18n } = useTranslation();
  const { localize, round } = useNumberFormat();
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.BenchmarkGoals);
  const isDynamicProdTypesEnabled = useFeatureFlagIsEnabled(FeatureFlags.DynamicProdTypes);
  const globalFilter = useSelector((state: RootState) => state.globalFilter);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [benchmarkChartData, setBenchmarkChartData] = useState<ChartData>({
    careUnits: [],
    chartData: [],
  });

  const competence_shares = ["SSK", "USK", "SPEC", "ST/LEG/AT/UL", "Övrigt"];

  const [chartDataLoaded, setChartDataLoaded] = useState<boolean>(false);

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);
  const chartFontSize = getChartFontSize(false, isLargeScreen);
  const axisItemsFontSize = 18;

  const fetchBMChartData = async () => {
    let productionTypeNames: string[] = [];
    let prodTypeIdList: number[] = [];

    if (isDynamicProdTypesEnabled) {
      prodTypeIdList = selectedProdTypeIdList ? selectedProdTypeIdList?.map((x) => Number(x.value)) : [];
    } else {
      productionTypeNames = selectedProductionTypes;
    }

    let result: ChartData = {
      chartData: [],
      careUnits: [],
    };

    if (endDateErrors && endDateErrors.length < 1) {
      if (globalFilter.careProviderId && globalFilter.careProviderId > 0) {
        await ResultService.getBenchmarkChartData(
          productionTypeNames,
          selectedInput.value,
          selectedCompetenceInput.value,
          globalFilter.careProviderId,
          selectedCareLevel?.value,
          selectedMedicalSpeciality?.value,
          getChartType(),
          selectedCompetenceType.value,
          selectedContactType.value,
          dateRange.startDate.toISOString(),
          dateRange.endDate.toISOString(),
          scenario,
          prodTypeIdList
        )
          .then((res) => {
            const data: ChartDataResponse = res;

            data?.chartData?.sort((a: ChartDataObject, b: ChartDataObject) => {
              return competence_shares.indexOf(a.name) - competence_shares.indexOf(b.name);
            });
            result = data;
          })
          .catch(() => {
            setErrorNotification && setErrorNotification("form error");
          });
      }
    }

    return result;
  };

  const getChartType = () => {
    if (globalFilter.filterCareUnits.units.length === 1 && globalFilter.childCareUnits.length === 0) {
      if (globalFilter.filterCareUnitType !== null && globalFilter.filterCareUnitType === CareUnitTypeEnum.OUTPATIENT) {
        return "Outpatient";
      } else if (
        globalFilter.filterCareUnitType !== null &&
        globalFilter.filterCareUnitType === CareUnitTypeEnum.INPATIENT
      ) {
        return "Inpatient";
      } else if (
        globalFilter.filterCareUnitType !== null &&
        globalFilter.filterCareUnitType === CareUnitTypeEnum.EMERGENCY
      ) {
        return "Emergency";
      } else {
        return "Doctors";
      }
    } else {
      if (
        globalFilter.filterCareUnits.careUnitType !== null &&
        globalFilter.filterCareUnits.careUnitType === CareUnitTypeEnum.OUTPATIENT
      ) {
        return "Outpatient";
      } else {
        return "Inpatient";
      }
    }
  };

  //defining chart colors
  const processChartData = async (data: ChartDataResponse) => {
    const modifiedChartData: ChartData = {
      careUnits: data?.careUnits,
      chartData: [],
    };

    data?.chartData.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));

    data?.chartData.map((dataObj: ChartDataObject) => {
      const st: BenchmarkDataObj = {
        id: uuid(),
        color: dataObj.color,
        data: dataObj.data as number[],
        name: dataObj.name,
        sortOrder: dataObj.sortOrder,
      };
      modifiedChartData.chartData.push(st);
    });

    if (isFeatureFlagEnabled) {
      if (
        (globalFilter.childCareUnits.length === 0 && globalFilter.filterCareUnitType === CareUnitTypeEnum.INPATIENT) ||
        (globalFilter.childCareUnits.length > 0 &&
          globalFilter.filterCareUnits.careUnitType === CareUnitTypeEnum.INPATIENT)
      ) {
        const targetProductivitySeries = {
          data: data.tagetProductivity?.map((tp) => {
            const careUnitName = tp.careUnitName;
            const index = modifiedChartData.careUnits.indexOf(careUnitName);
            return [index, tp.targetProductivity];
          }),
          name: translate("target_productivity"),
          id: uuid(),
          color: "#F25C05",
          type: "scatter",
          zIndex: 10000,
        };

        modifiedChartData.chartData.push(targetProductivitySeries);
      }
    }

    setBenchmarkChartData(modifiedChartData);
    setChartDataLoaded(true);
  };

  useEffect(() => {
    // Effect for main chart
    const fetchData = async () => {
      const chartData = await fetchBMChartData();
      await processChartData(chartData as ChartDataResponse);
    };

    ((!isDynamicProdTypesEnabled && selectedProductionTypes.length > 0) ||
      (isDynamicProdTypesEnabled && selectedProdTypeIdList && selectedProdTypeIdList.length > 0)) &&
      fetchData();

    if (afterFetchingChartData) {
      afterFetchingChartData();
    }
  }, [
    dateRange,
    globalFilter.careProviderId,
    globalFilter.filterCareUnitType,
    selectedInput.value,
    selectedMedicalSpeciality?.value,
    selectedCareLevel?.value,
    selectedProductionTypes,
    scenario,
    selectedProdTypeIdList,
  ]);

  const isWorkingHours = selectedInput.value === "working_hours";
  const isOrgUserType1 =
    globalFilter.filterCareUnitType !== null && globalFilter.filterCareUnitType === CareUnitTypeEnum.OUTPATIENT;
  const isOrgUserType2 =
    globalFilter.filterCareUnitType !== null && globalFilter.filterCareUnitType === CareUnitTypeEnum.INPATIENT;

  let result = "";

  if (isOrgUserType1) {
    result = isWorkingHours ? translate("working_hours_per_visit") : translate("cost_per_visit");
  } else if (isOrgUserType2) {
    result = isWorkingHours ? translate("WorkingHoursPerDay") : translate("CostPerDay");
  } else {
    result = isWorkingHours ? translate("working_hours_per_ed_visit") : translate("cost_per_ed_visit");
  }

  const benchmarkingOptions = {
    chart: {
      type: "bar",
      height: "50%",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: benchmarkChartData.careUnits,
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          // eslint-disable-next-line
          // @ts-ignore
          const valueName = this.value.name;

          if (valueName !== undefined && valueName === globalFilter.filterCareUnitLabel) {
            return (
              `<div><span style="color: #548147; font-weight: bold; font-size: ${axisItemsFontSize}px">` +
              valueName +
              "</span></div>"
            );
          } else if (valueName !== undefined && valueName !== globalFilter.filterCareUnitLabel) {
            return `<span style="font-weight: normal; font-size: ${axisItemsFontSize}px">` + valueName + "</span>";
          } else {
            return valueName;
          }
        },
      },
      title: "",
    },
    yAxis: {
      reversedStacks: false,
      min: 0,
      title: {
        text: result,

        style: {
          fontSize: chartFontSize,
        },
      },
      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: chartFontSize,
      },
      style: {
        fontSize: chartFontSize,
      },
    },
    plotOptions: {
      scatter: {
        marker: {
          symbol: "circle",
        },
      },
      series: {
        stacking: "normal",
        point: {},
      },
    },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as Highcharts.Point;
        const tooltipValue = localize(round(point.y || 0, 1), i18n.language);
        return `${point.series.name} ${tooltipValue}`;
      },
    },
    series: benchmarkChartData.chartData,
  };

  return (
    <div className="h-full w-full ">
      {chartDataLoaded ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={benchmarkingOptions}
          containerProps={{
            style: { height: "100%", width: "100%" },
          }}
        />
      ) : null}
    </div>
  );
};

export default BenchmarkingChart;
