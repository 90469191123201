import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import HotTable from "@handsontable/react";
import { useTranslation } from "react-i18next";

import ExpandableButton from "../../../../components/expandable-button";
import ControlIcon from "../../../../components/control-icon/control-icon";
import { ControlIconTypes } from "../../../../components/control-icon/icon-types";
import { Button } from "../../../../components";
import useGetActivityShiftTableConfigs from "./hooks/useGetActivityShiftTableConfigs";
import useGetActivityShiftsData from "../hooks/useGetActivityShiftsData";
import { ActivityAnalysisSubmitEventRef } from "../../../../interfaces/analytics/activity-analysis-submit-event-ref";
import { ActivityAnalysisWithinShiftsConfigReturntype } from "../../../../interfaces/analytics/activity-analysis-within-shifts-configs-return-type";
import ActivityAnalysisService from "../../../../services/activityAnalysisService";
import { ActivityAnalysisShift } from "../../../../interfaces/analytics/activity-analysis-shift";
import { ActivityAnalysisIsEditedRefType } from "../../../../interfaces/analytics/activity-analysis-is-edited-ref-type";
import useGetWorkCompetencesForCareUnit from "../hooks/useGetWorkCompetencesForCareUnit";
import { useBlocker } from "react-router-dom";
import SaveChangesConfirmation from "../../../../components/SaveChangesConfirmation/SaveChangesConfirmation";

interface Prop {
  careUnitId: number;
  year: number;
  scenarioId: number;
  setSubmitStatus: React.Dispatch<React.SetStateAction<number>>;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  onNextButtonClick: () => void;
  onPrevButtonClick: () => void;
  isEditedRef: React.MutableRefObject<ActivityAnalysisIsEditedRefType>;
}

export const ActivityAnalysisWithinShifts = forwardRef<ActivityAnalysisSubmitEventRef, Prop>(
  (
    {
      careUnitId,
      year,
      scenarioId,
      setIsSubmitting,
      setSubmitStatus,
      onNextButtonClick,
      onPrevButtonClick,
      isEditedRef,
    }: Prop,
    ref
  ): JSX.Element => {
    const isWithinShifts = true;

    const [isTableChanged, setIsTableChanged] = useState<boolean>(false);

    const [isSaveChangesNotificationOpen, setIsSaveChangesNotificationOpen] = useState(false);
    const [isSaveChangesNotificationOpenOnTabChange, setIsSaveChangesNotificationOpenOnTabChange] = useState<{
      state: boolean;
      index?: -1 | 1;
    }>({ state: false });

    const hook = useGetActivityShiftsData(careUnitId, scenarioId, year, true, isEditedRef);

    const workCompetences = useGetWorkCompetencesForCareUnit(careUnitId);

    const blocker = useBlocker(
      ({ currentLocation, nextLocation }) => isTableChanged && currentLocation.pathname !== nextLocation.pathname
    );

    const configs: ActivityAnalysisWithinShiftsConfigReturntype = useGetActivityShiftTableConfigs(
      hook.data,
      hook.setData,
      isWithinShifts,
      isEditedRef,
      workCompetences,
      setIsTableChanged
    );

    const saveWithinShiftData = async (): Promise<boolean> => {
      setIsSubmitting(true);

      const sumRowRemoved = hook.data.filter((x) => x?.id !== "total_row");

      const modifiedData = sumRowRemoved as ActivityAnalysisShift[];

      return await ActivityAnalysisService.postActivityAnalysisShift(
        careUnitId as number,
        scenarioId,
        year,
        isWithinShifts,
        modifiedData
      )
        .then(() => {
          setIsSubmitting(false);
          setSubmitStatus(1);
          setIsTableChanged(false);
          hook.fetch();

          return true;
        })
        .catch(() => {
          setIsSubmitting(false);
          setSubmitStatus(2);

          return false;
        });
    };

    useImperativeHandle(ref, () => ({
      ...ref,
      saveWithinShiftData,
    }));

    const handleAddNewRowClick = () => {
      configs.addNewRow();
    };

    const { t: translate } = useTranslation();

    const handleNextButtonClick = () => {
      if (isTableChanged) {
        setIsSaveChangesNotificationOpenOnTabChange({ state: true, index: 1 });
      } else {
        onNextButtonClick();
      }
    };

    const handlePrevButtonClick = () => {
      if (isTableChanged) {
        setIsSaveChangesNotificationOpenOnTabChange({ state: true, index: -1 });
      } else {
        onPrevButtonClick();
      }
    };

    useEffect(() => {
      if (blocker.state === "blocked") {
        setIsSaveChangesNotificationOpen(true);
      }
    }, [blocker.state]);

    const handleRightButtonClick = () => {
      blocker.proceed && blocker.proceed();
    };

    const handleLeftButtonClick = async () => {
      await saveWithinShiftData();

      setIsSaveChangesNotificationOpen(false);
      blocker.reset && blocker.reset();
    };

    const handleRightButtonClickOnTabChange = (btnClickFnc: () => void) => {
      btnClickFnc();
    };

    const handleLeftButtonClickOnTabChange = async (btnClickFnc: () => void) => {
      const isSuccessful = await saveWithinShiftData();
      setIsSaveChangesNotificationOpenOnTabChange({ state: false });

      if (isSuccessful) {
        btnClickFnc();
      }
    };

    return (
      <div className="col-start-1 col-end-13">
        <SaveChangesConfirmation
          isSaveChangesNotificationOpen={isSaveChangesNotificationOpen}
          onLeftButtonClick={handleLeftButtonClick}
          onRightButtonClick={handleRightButtonClick}
        />
        <SaveChangesConfirmation
          isSaveChangesNotificationOpen={isSaveChangesNotificationOpenOnTabChange.state}
          onLeftButtonClick={() => {
            if (
              isSaveChangesNotificationOpenOnTabChange.index !== undefined &&
              isSaveChangesNotificationOpenOnTabChange.index === -1
            ) {
              handleLeftButtonClickOnTabChange(onPrevButtonClick);
            } else if (
              isSaveChangesNotificationOpenOnTabChange.index !== undefined &&
              isSaveChangesNotificationOpenOnTabChange.index === 1
            ) {
              handleLeftButtonClickOnTabChange(onNextButtonClick);
            }
          }}
          onRightButtonClick={() => {
            if (
              isSaveChangesNotificationOpenOnTabChange.index !== undefined &&
              isSaveChangesNotificationOpenOnTabChange.index === -1
            ) {
              handleRightButtonClickOnTabChange(onPrevButtonClick);
            } else if (
              isSaveChangesNotificationOpenOnTabChange.index !== undefined &&
              isSaveChangesNotificationOpenOnTabChange.index === 1
            ) {
              handleRightButtonClickOnTabChange(onNextButtonClick);
            }
          }}
        />
        <div className="rounded px-2">
          <div className="h-fit">
            <div className="relative h-[65vh] overflow-y-scroll">
              <HotTable {...configs.props} data={hook.data} />
            </div>
            <div className="mt-4 flex w-full items-center justify-between gap-4 pr-4">
              <div className="z-[1000] mt-2">
                <ExpandableButton text={translate("add_new_row")} onClick={handleAddNewRowClick} />
              </div>

              <div className="flex items-center gap-4">
                <Button
                  onClick={handlePrevButtonClick}
                  text={translate("prev")}
                  variant={"button--secondary-optional"}
                  additionalStyles={"xl:w-[10.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[8.313rem]"}
                  icon={<ControlIcon type={ControlIconTypes.perv} />}
                />
                <Button
                  onClick={handleNextButtonClick}
                  text={translate("next")}
                  variant={"button--secondary-optional-with-border"}
                  additionalStyles={"xl:w-[10.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[8.313rem] float-right"}
                  icon={<ControlIcon type={ControlIconTypes.next} />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

ActivityAnalysisWithinShifts.displayName = "ActivityAnalysisWithinShifts";

export default ActivityAnalysisWithinShifts;
