import { useEffect, useState } from "react";
import ProdTypeService from "../../../services/prodTypeServices";
import BaseDataRetrieveHookReturn from "../../../interfaces/hooks/baseDataRetrieveHookReturn";

const useGetProdTypeAllocationBySetting = (
  careUnitTypeId: number | null,
  settingTypeId: number
): BaseDataRetrieveHookReturn<number[]> => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [prodTypeAllocation, setProdTypeAllocation] = useState<number[]>([]);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);

    if (careUnitTypeId) {
      await ProdTypeService.getProductionTypeAllocationBySetting(careUnitTypeId, settingTypeId)
        .then((res) => {
          const responseData: number[] = res.data.prodTypeIds;
          setProdTypeAllocation(responseData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setProdTypeAllocation([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [careUnitTypeId]);

  return {
    data: prodTypeAllocation,
    fetch: fetchData,
    isLoading,
  };
};

export default useGetProdTypeAllocationBySetting;
