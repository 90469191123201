import { useEffect, useState } from "react";
import { PhysicianUnitsProductivityComparisonData } from "../../../../../../interfaces/productivity/physicianUnitsProductivityComparisonData";

const usePhysicianUnitsComparisonPrompt = (
  isDynamicProdTypesFeatureFlagEnabled: boolean,
  data: PhysicianUnitsProductivityComparisonData | null
) => {
  const [prompt, setPrompt] = useState<string | null>(null);

  const intro =
    'Läkarenheten som ska analyseras har följande plan jämfört med referensgruppen. Om något värde är "NA" i en kategori så utgår den kategorin ur analysen. Per kategori ser skillnaderna ut så här:';

  useEffect(() => {
    if (data) {
      const linesArray = [
        `"Arbetstimmar per akutmottagningsbesök", Läkarenhet ${
          data.workingHoursPerEmergencyVisit ? data.workingHoursPerEmergencyVisit : "NA"
        }, Referensgrupp ${data.restWorkingHoursPerEmergencyVisit ? data.restWorkingHoursPerEmergencyVisit : "NA"}`,
        `"Läkarkontakter per dag", Läkarenhet ${data.dailyOutpatientVisitsPerFTE}, Referensgrupp ${data.restDailyOutpatientVisitsPerFTEAvr}`,
        `"Arbetstimmar per vårddygn", Läkarenhet ${data.workingHoursPerInpatientDay * 60}, Referensgrupp ${
          data.restWorkingHoursPerInpatientDayAvr * 60
        }`,
        `"Arbetstimmar per operation", Läkarenhet ${
          data.workingHoursPerOperation ? data.workingHoursPerOperation : "NA"
        }, Referensgrupp ${data.restWorkingHoursPerOperation ? data.restWorkingHoursPerOperation : "NA"}`,
        `"Arbetstimmar per operationstid", Läkarenhet ${
          data.workingHoursPerOperationTime ? data.workingHoursPerOperationTime : "NA"
        }, Referensgrupp ${data.restWorkingHoursPerOperationTime ? data.restWorkingHoursPerOperationTime : "NA"}`,
        `"Andel produktionstid", Läkarenhet ${data.surgeryTimePercentage}%, Referensgrupp ${data.restSurgeryTimePercentage}%`,
        `"Andel konsultativt stöd", Läkarenhet ${data.consultingTimePercentage}%, Referensgrupp ${data.restConsultingTimePercentage}%`,
        `"Andel utbildning", Läkarenhet ${data.educationTimePercentage}%, Referensgrupp ${data.restEducationTimePercentage}%`,
        `"Andel icke klinisk tid", Läkarenhet ${data.nonClinicalTimePercentage}%, Referensgrupp ${data.restNonClinicalTimePercentage}%`,
        `"Andel ledningstid" Läkarenhet ${data.managementTimePercentage}%, Referensgrupp ${data.restManagementTimePercentage}%`,
      ];

      if (isDynamicProdTypesFeatureFlagEnabled) {
        linesArray.push(
          `"Andel extratid” Läkarenhet ${data.extraTimePercentage}%, Referensgrupp ${data.restExtraTimePercentage}%`
        );
      }
      
      const lines = linesArray.filter(Boolean).join("");

      setPrompt(`${intro}${lines}`);
    }
  }, [data]);

  return prompt;
};

export default usePhysicianUnitsComparisonPrompt;
