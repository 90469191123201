import { DropdownEditor } from "handsontable/editors";
import { CellProperties } from "handsontable/settings";

type Option = { value: string; label: string };
type OptionsToPrepare = Array<string | Option> | Record<string, string> | string;

export class MultiSelectEditor extends DropdownEditor {
  isMultiple = true;

  private editorContainer!: HTMLDivElement;
  private contentWrapper!: HTMLDivElement;
  private valueDisplay!: HTMLSpanElement;
  private arrow!: HTMLDivElement;
  private dropdownContainer!: HTMLDivElement;
  private optionsList!: HTMLDivElement;
  private selectedOptions: Set<string> = new Set();
  private _opened = false; // Changed from !: boolean to = false for proper initialization
  cellProperties!: CellProperties;
  private cellSelections: Map<string, Set<string>> = new Map();

  init() {
    this.editorContainer = this.hot.rootDocument.createElement("div");

    this.editorContainer.className = "htCustomMultiSelectEditor";

    this.contentWrapper = this.hot.rootDocument.createElement("div");
    this.contentWrapper.className = "currentValue";

    this.valueDisplay = this.hot.rootDocument.createElement("span");
    this.contentWrapper.appendChild(this.valueDisplay);

    this.editorContainer.appendChild(this.contentWrapper);

    this.dropdownContainer = this.hot.rootDocument.createElement("div");
    this.dropdownContainer.className = "htCustomMultiSelectDropdownMenu handsontable";
    this.dropdownContainer.style.position = "absolute";
    this.dropdownContainer.style.display = "none";

    this.optionsList = this.hot.rootDocument.createElement("div");
    this.optionsList.className = "htCore htCustomMultiSelectDropdownListContainer";

    this.dropdownContainer.appendChild(this.optionsList);
    this.hot.rootElement.appendChild(this.editorContainer);
    this.hot.rootElement.appendChild(this.dropdownContainer);

    const style = this.hot.rootDocument.createElement("style");
    style.textContent = `
      .htCustomMultiSelectEditor {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 2px;
        background: white;
        border: none;
      }
      .htCustomMultiSelectEditor .currentValue {
        opacity: 0;
        flex: 1;
        padding: 2px 4px;
        min-height: 23px;
        line-height: 23px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      } 
      .htCustomMultiSelectDropdownMenu {
        background: white;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        max-height: 200px;
        overflow-y: auto;
      }
      .htCustomMultiSelectDropdownListContainer {
        padding: 2px 0;
      }
      .htDropdownListItem {
        display: flex;
        align-items: center; 
        gap: 8px;
        padding: 4px 8px;
      }
      .htDropdownListItem:hover {
        background: #f0f0f0;
      } 
      .htDropdownCheckbox {
        margin: 0;
      }
    `;
    this.hot.rootDocument.head.appendChild(style);
  }

  prepare(
    row: number,
    col: number,
    prop: string,
    td: HTMLTableCellElement,
    originalValue: any,
    cellProperties: CellProperties
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    if (!this.optionsList) {
      this.init();
    }

    const cellKey = `${row},${col}`;

    if (!this.cellSelections.has(cellKey)) {
      this.cellSelections.set(cellKey, new Set());
    }
    this.selectedOptions = new Set();

    if (originalValue) {
      originalValue
        .split(",")
        .map((value: string) => value.trim())
        .forEach((value: string) => this.selectedOptions.add(value));
    }

    const selectOptions = this.cellProperties.multiSelectOptions;
    const options = this.prepareOptions(selectOptions as OptionsToPrepare);

    this.optionsList.innerHTML = "";

    Object.entries(options).forEach(([key, value]) => {
      const optionContainer = this.hot.rootDocument.createElement("div");
      optionContainer.className = "htDropdownListItem";

      // Create checkbox
      const checkbox = this.hot.rootDocument.createElement("input");
      checkbox.type = "checkbox";
      checkbox.className = "htDropdownCheckbox";
      checkbox.checked = this.selectedOptions.has(key);

      // Create label
      const label = this.hot.rootDocument.createElement("label");
      label.innerText = value;

      // Add to container
      optionContainer.appendChild(checkbox);
      optionContainer.appendChild(label);
      optionContainer.dataset.value = key;

      if (this.selectedOptions.has(key)) {
        optionContainer.classList.add("selected");
      }

      // Separate click handlers with stopPropagation
      checkbox.addEventListener("click", (e) => {
        e.stopPropagation();
        this.handleOptionClick(key, optionContainer);
      });

      label.addEventListener("click", (e) => {
        e.stopPropagation();
        checkbox.checked = !checkbox.checked;
        this.handleOptionClick(key, optionContainer);
      });

      this.optionsList.appendChild(optionContainer);
    });

    this.updateDisplayValue();
  }

  prepareOptions(optionsToPrepare: OptionsToPrepare): Record<string, string> {
    let preparedOptions: Record<string, string> = {};

    if (Array.isArray(optionsToPrepare)) {
      optionsToPrepare.forEach((option) => {
        if (typeof option === "object" && option !== null && "value" in option && "label" in option) {
          preparedOptions[option.value] = option.label;
        } else {
          preparedOptions[option as string] = option as string;
        }
      });
    } else if (typeof optionsToPrepare === "object" && optionsToPrepare !== null) {
      preparedOptions = { ...optionsToPrepare };
    } else if (typeof optionsToPrepare === "string") {
      optionsToPrepare.split("|").forEach((option) => {
        const trimmedOption = option.trim();
        preparedOptions[trimmedOption] = trimmedOption;
      });
    }

    return preparedOptions;
  }

  handleOptionClick(value: string, element: HTMLElement) {
    if (this.isMultiple) {
      if (this.selectedOptions.has(value)) {
        this.selectedOptions.delete(value);
        element.classList.remove("selected");
      } else {
        this.selectedOptions.add(value);
        element.classList.add("selected");
      }
      this.updateDisplayValue();
    } else {
      this.selectedOptions.clear();
      this.selectedOptions.add(value);
      this.updateDisplayValue();
      this.close();
      this.finishEditing();
    }

    const cellKey = `${this.row},${this.col}`;
    this.cellSelections.set(cellKey, this.selectedOptions);
  }

  updateDisplayValue() {
    const selectedValues = Array.from(this.selectedOptions);
    this.valueDisplay.innerText = selectedValues.join(", ") || "";
  }

  getValue() {
    const cellKey = `${this.row},${this.col}`;
    const cellSelection = this.cellSelections.get(cellKey) || new Set();
    const values = Array.from(cellSelection);
    return this.isMultiple ? (values.length ? values.join(", ") : "") : values[0] || "";
  }

  setValue(value: string): void {
    const cellKey = `${this.row},${this.col}`;
    const cellSelection = new Set<string>();
    if (value) {
      value.split(",").forEach((val) => cellSelection.add(val.trim()));
    }
    this.cellSelections.set(cellKey, cellSelection);
    this.selectedOptions = cellSelection;
    this.updateDisplayValue();
  }

  open() {
    const cellRect = this.getEditedCellRect();

    if (cellRect) {
      const { top, start, width, height } = cellRect;
      this._opened = true;

      this.dropdownContainer.style.display = "block";
      this.dropdownContainer.style.top = `${top + height}px`;
      this.dropdownContainer.style.left = `${start}px`;
      this.dropdownContainer.style.width = `${width}px`;
      this.dropdownContainer.style.zIndex = "9999";
    } else {
      console.error("cellRect is undefined");
    }
  }

  close() {
    this._opened = false;
    this.dropdownContainer.style.display = "none";
  }

  focus() {
    this.editorContainer.focus();
  }
}
