import { useEffect, useState } from "react";
import BaseDataRetrieveHookReturn from "../../../interfaces/hooks/baseDataRetrieveHookReturn";
import { ProdType } from "../../../interfaces/productivity/workShifts/prodType";
import ProdTypeService from "../../../services/prodTypeServices";

const useGetProductionTypeData = (careUnitTypeId: number | null): BaseDataRetrieveHookReturn<ProdType[]> => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [prodTypes, setProdTypes] = useState<ProdType[]>([]);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);

    if (careUnitTypeId) {
      await ProdTypeService.getProductionTypesByCareType(careUnitTypeId)
        .then((res) => {
          const responseData: ProdType[] = res.data;
          setProdTypes(responseData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setProdTypes([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [careUnitTypeId]);

  return {
    data: prodTypes,
    fetch: fetchData,
    isLoading,
  };
};

export default useGetProductionTypeData;
