import React, { Dispatch, PropsWithChildren, SetStateAction } from "react";
import "./DynamicModal.css";

interface DynamicModalProps {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  title: string;
  maxWidth?: string;
  className?: string;
}

const DynamicModal: React.FC<PropsWithChildren<DynamicModalProps>> = ({
  isOpen,
  onClose,
  title,
  children,
  maxWidth = "max-w-4xl",
  className = "",
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal-overlay"
      onClick={() => onClose(false)}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="modal-container">
        <span className="modal-align-trick" aria-hidden="true">
          &#8203;
        </span>

        <div
          className={`modal-content ${maxWidth} ${className}`}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="modal-header">
            <h3 id="modal-title" className="modal-title">
              {title}
            </h3>
            <button onClick={() => onClose(false)} className="modal-close-button" aria-label="Close modal">
              <svg className="modal-close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Content */}
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DynamicModal;
