import React, { useEffect, useState } from "react";
import { Button, DatePicker, HideToggle, Input } from "../../../../../components";
import { useTranslation } from "react-i18next";
import ControlIcon from "../../../Components/ControlIcon/ControlIcon";
import { ControlIconTypes } from "../../../Components/ControlIcon/IconTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import useGetHistoricQueueCuttingPlan from "../../Hooks/useGetHistoricQueueCuttingPlan";
import "./QueueDetailsSidePanel.css";
import { QueueDetailSidePanelWithMultiSelectProps } from "../../../../../interfaces/props";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";
import { QueueCuttingPlanRequest } from "../../../../../interfaces/production-plan/outpatient/queue/queueCuttingPlan";
import queueCuttingPlanService from "../../../../../services/queueCuttingPlanService";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";
import { ToastContainer, toast } from "react-toastify";
import OutpatientMultiselectFilter from "../../../Components/OutpatientMultiselectFilter/OutpatientMultiselectFilter";
import { useGetQueueCuttingPlan } from "../../Hooks/useGetQueueCuttingPlan";
import NotificationBox from "../../../../../components/NotificationBox/NotificationBox";

const nextIcon = <ControlIcon type={ControlIconTypes.next} />;
const prevIcon = <ControlIcon type={ControlIconTypes.perv} />;

const QueueDetailsSidePanelWithMultiSelect = ({
  selectedTabIndex,
  setSelectedTabIndex,
  queueDetailRequest,
  setQueueDetailRequest,
  isSaveButtonClicked,
  setIsSaveButtonClicked,
  isOutpatientParametersModifying,
  setIsOutpatientParametersModifying,
  nextAvailableTabIndex,
  isResetButtonClicked,
  setIsResetButtonClicked,
  setNextAvailableTabIndex,
  isTabBarClicked,
  setIsTabBarClicked,
  clickedTabIndex,
  isQueueForecastVisible,
  setIsQueueForecastVisible,
  setIsQueueCuttingPlanUpdated,
}: QueueDetailSidePanelWithMultiSelectProps) => {
  const { t: translate } = useTranslation();
  const locale = localStorage.getItem("i18nextLng") === "en" ? "en" : "sv-SE";

  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [queueCuttingReductionGoalDateError, setQueueCuttingReductionGoalDateError] = useState<string | null>(null);
  const [isSaveConfirmationModalBoxOpen, setIsSaveConfirmationModalBoxOpen] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const isDetailPlanFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.DetailedPlanMultiSelect);

  const getNumberOfDelayedUpToDate = () => {
    const currentDateObj = new Date();
    currentDateObj.setDate(currentDateObj.getDate() - (currentDateObj.getDay() % 7));
    return currentDateObj;
  };

  const historicQueueCuttingPlan = useGetHistoricQueueCuttingPlan(careUnitId, queueDetailRequest);
  const [queueCuttingPlanByFilters] = useGetQueueCuttingPlan(careUnitId, scenario, year, queueDetailRequest);

  const toISOIgnoreTimezone = (inputDate: Date) => {
    return (
      inputDate.getFullYear() +
      "-" +
      ("0" + (inputDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + inputDate.getDate()).slice(-2) +
      "T00:00:00.000Z"
    );
  };

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.MultipleQueueCuttingPlans);
  const isQueueForecastFeatureEnabled = useFeatureFlagIsEnabled(FeatureFlags.QueuePlanForecast);

  const [queueCuttingPlan, setQueueCuttingPlan] = useState(0);
  const [queueCuttingGoalDate, setQueueCuttingGoalDate] = useState<Date>(new Date(year, 11, 31));
  const [, setIsQueuePlanResetting] = useState(false);

  useEffect(() => {
    setQueueCuttingGoalDate(new Date(year, 11, 31));
  }, [year]);

  const saveQueueCuttingPlanData = async (): Promise<boolean> => {
    isSaveConfirmationModalBoxOpen ? setIsSaveConfirmationModalBoxOpen(false) : null;

    if (queueCuttingGoalDate) {
      const date = new Date(queueCuttingGoalDate);

      if (date.getFullYear() !== year) {
        setQueueCuttingReductionGoalDateError(`${translate("goal_date_has_to_be_within")} ${year}`);
        return false;
      } else {
        setQueueCuttingReductionGoalDateError(null);
      }
    }

    setIsOutpatientParametersModifying(true);

    const payloadData = {
      careUnitId: careUnitId ? careUnitId : 0,
      queueCuttingPlan: queueCuttingPlan,
      queueCuttingGoalDate: queueCuttingGoalDate,
      professionIds: queueDetailRequest?.professionIds[0] === -1 ? [] : queueDetailRequest?.professionIds,
      contactTypeIds: queueDetailRequest?.contactTypeIds[0] === -1 ? [] : queueDetailRequest?.contactTypeIds,
      contactReasonGroupingIds:
        queueDetailRequest?.contactReasonGroupingIds[0] === -1 ? [] : queueDetailRequest?.contactReasonGroupingIds,
      typesOfVisit: queueDetailRequest?.typesOfVisit[0] === -1 ? [] : queueDetailRequest?.typesOfVisit,
      careTypeIds: queueDetailRequest?.careTypeIds[0] === -1 ? [] : queueDetailRequest?.careTypeIds,
      typesOfEmergency: queueDetailRequest?.typesOfEmergency[0] === -1 ? [] : queueDetailRequest?.typesOfEmergency,
      medicalSpecialtyIds:
        queueDetailRequest?.medicalSpecialtyIds[0] === -1 ? [] : queueDetailRequest?.medicalSpecialtyIds,
    };

    const data: QueueCuttingPlanRequest = {
      year: year,
      scenarioId: scenario,
      updateQueueCuttingPlanDto: payloadData,
    };

    let response = true;

    if (queueCuttingReductionGoalDateError === null) {
      const toastId = toast(
        <NotificationFloat varaint="loading" content={translate("saving_outpatient_parameters")} />,
        {
          icon: false,
          className: "custom-toast",
          containerId: "notificationBox",
          autoClose: false,
        }
      );

      await queueCuttingPlanService
        .saveQueueCuttingPlan(data)
        .then(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
            type: "success",
            autoClose: 3000,
          });
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          setIsEdited(false);
          setIsQueueCuttingPlanUpdated((prev) => prev + 1);
          response = true;
        })
        .catch(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
            type: "error",
            autoClose: 3000,
          });
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          response = false;
        });
    }

    return response;
  };

  const resetQueueCuttingPlanData = async (): Promise<boolean> => {
    setIsQueuePlanResetting(true);

    const toastId = toast(<NotificationFloat varaint="loading" content={translate("queue_reset_message")} />, {
      icon: false,
      className: "custom-toast",
      containerId: "notificationBox",
      autoClose: false,
    });

    let response = true;

    await queueCuttingPlanService
      .resetQueueCuttingPlan(careUnitId ? careUnitId : 0, scenario, year)
      .then(() => {
        toast.update(toastId, {
          render: <NotificationFloat varaint="success" content={translate("data_deletion_success_message")} />,
          autoClose: 3000,
          containerId: "notificationBox",
        });

        setIsResetButtonClicked(false);
        setIsQueuePlanResetting(false);
        setIsOutpatientParametersModifying(false);
        setIsQueueCuttingPlanUpdated((prev) => prev + 1);
        response = true;
      })
      .catch(() => {
        toast.update(toastId, {
          render: <NotificationFloat varaint="error" content={translate("data_deletion_fail_message")} />,
          autoClose: 3000,
          containerId: "notificationBox",
        });

        setIsResetButtonClicked(false);
        setIsQueuePlanResetting(false);
        setIsOutpatientParametersModifying(false);
        response = false;
      });

    return response;
  };

  useEffect(() => {
    if (isSaveButtonClicked && (isDetailPlanFeatureFlagEnabled ? selectedTabIndex === 3 : selectedTabIndex === 2)) {
      saveQueueCuttingPlanData();
    }
  }, [isSaveButtonClicked]);

  useEffect(() => {
    if (isResetButtonClicked && (isDetailPlanFeatureFlagEnabled ? selectedTabIndex === 3 : selectedTabIndex === 2)) {
      resetQueueCuttingPlanData();
    }
  }, [isResetButtonClicked]);

  useEffect(() => {
    if (isTabBarClicked) {
      if (isEdited) {
        setIsSaveConfirmationModalBoxOpen(true);
      } else {
        setSelectedTabIndex(() => clickedTabIndex);
        setIsTabBarClicked(false);
      }
    }
  }, [isTabBarClicked]);

  const handleProductionQueueEndDateChange = (date: Date) => {
    setIsEdited(true);
    queueCuttingReductionGoalDateError != null
      ? setQueueCuttingReductionGoalDateError(null)
      : queueCuttingReductionGoalDateError;

    setQueueCuttingGoalDate(new Date(date));
  };

  const handleQueueCuttingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);

    const newValue = parseInt(e.target.value);
    setQueueCuttingPlan(isNaN(newValue) ? 0 : newValue);
  };

  const displayOutpatientPlanSaveNotification = async (nextTabIndex: number) => {
    if (isEdited && (isDetailPlanFeatureFlagEnabled ? selectedTabIndex === 3 : selectedTabIndex === 2)) {
      setNextAvailableTabIndex(() => nextTabIndex);
      setIsSaveConfirmationModalBoxOpen(true);
    } else {
      setSelectedTabIndex(() => nextTabIndex);
    }
  };

  const handleNotificationBoxSaveButtonClick = async () => {
    if (isDetailPlanFeatureFlagEnabled ? selectedTabIndex === 3 : selectedTabIndex === 2) {
      await saveQueueCuttingPlanData();
      if (isTabBarClicked) {
        setIsTabBarClicked(false);
        setSelectedTabIndex(() => clickedTabIndex);
      } else {
        setSelectedTabIndex(nextAvailableTabIndex);
      }
    }
  };

  const handleNotificationBoxDiscardSaveButtonClick = () => {
    setIsSaveConfirmationModalBoxOpen(false);
    if (isTabBarClicked) {
      setIsTabBarClicked(false);
      setSelectedTabIndex(() => clickedTabIndex);
    } else {
      setSelectedTabIndex(nextAvailableTabIndex);
    }
  };

  const toogleForecast = () => {
    const visibility: boolean = isQueueForecastVisible;
    setIsQueueForecastVisible(!visibility);
  };

  return (
    <div className="info-wrapper">
      <ToastContainer
        containerId={"notificationBox"}
        style={{ width: "400px" }}
        position="top-right"
        hideProgressBar={true}
        closeButton={false}
      />
      {isSaveConfirmationModalBoxOpen ? (
        <NotificationBox
          content={translate("do_you_want_to_save_changes")}
          title={translate("save_changes")}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxSaveButtonClick}
          onRightButtonClick={handleNotificationBoxDiscardSaveButtonClick}
          isLeftButtonDisabled={false}
          isRightButtonDisabled={false}
        />
      ) : null}
      <div className="px-4">
        <div className="sidearea__text--container--v4">
          <h1 className="sidearea__text--title--v4">
            {translate("number_of_delayed_visits")} <br />
            {getNumberOfDelayedUpToDate().toLocaleDateString(locale)}
          </h1>
          <p className="sidearea__text--value--v4">{historicQueueCuttingPlan.data}</p>
        </div>
      </div>
      {isFeatureFlagEnabled && (
        <div className="px-4">
          <div className="sidearea__text--container--v4">
            <h1 className="sidearea__text--title--v4">{translate("number_of_visits_queue_reduction_plan")}</h1>
            <p className="sidearea__text--value--v4">{queueCuttingPlanByFilters}</p>
          </div>
        </div>
      )}

      {isQueueForecastFeatureEnabled ? (
        <div className="flex items-center px-4">
          <div className="sidearea__text--title--v4 w-5/6">{translate("show_forecast")}</div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle isVisible={isQueueForecastVisible} onToggle={toogleForecast} className="xl:mt-1" />
          </div>
        </div>
      ) : null}

      <div className="my-3 px-4">
        <hr />
      </div>

      <div className="input-area px-4">
        <span className="p-1-v-2 block font-bold">{translate("backlog_decrease_plan")}</span>
        <div className="">
          <Input
            name="queueCuttingPlan"
            value={queueCuttingPlan.toString()}
            handleInputChange={handleQueueCuttingInputChange}
            placeholder={translate("number_of_visits_backlog")}
            disabled={isCareUnitReadOnly !== null ? isCareUnitReadOnly : false}
          />
        </div>
        <div className="">
          <DatePicker
            placement="bottom"
            handleInputChange={handleProductionQueueEndDateChange}
            id="queueCuttingReductionGoalDate"
            name="queueCuttingReductionGoalDate"
            placeholder={translate("queue_cutting_reduction_goal_date")}
            value={toISOIgnoreTimezone(queueCuttingGoalDate)}
            error={queueCuttingReductionGoalDateError}
          />
        </div>

        <OutpatientMultiselectFilter
          setOutPatientMultiSelectRequest={setQueueDetailRequest}
          outPatientMultiSelectRequest={queueDetailRequest}
          selectedTabIndex={selectedTabIndex}
        />

        <div className="mt-4 flex w-full items-center gap-4">
          <Button
            onClick={() => {
              //setIsOutpatientChartParametersError(false);
              setQueueCuttingReductionGoalDateError(null);
              displayOutpatientPlanSaveNotification(selectedTabIndex - 1);
            }}
            text={translate("prev")}
            variant={"button--secondary-optional"}
            additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
            icon={prevIcon}
            disabled={isOutpatientParametersModifying}
          />

          <Button
            onClick={() => {
              //setIsOutpatientChartParametersError(false);
              setQueueCuttingReductionGoalDateError(null);
              displayOutpatientPlanSaveNotification(selectedTabIndex + 1);
            }}
            text={translate("next")}
            variant={"button--secondary-optional-with-border"}
            additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem] float-right"}
            icon={nextIcon}
            disabled={isOutpatientParametersModifying}
          />
        </div>
      </div>
    </div>
  );
};

export default QueueDetailsSidePanelWithMultiSelect;
