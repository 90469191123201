import HttpCommonService from "./httpCommonService";

const DashboardService = {
  getSiteId: async (id: number) => {
    return await HttpCommonService.getAsync(`api/dashboard/get-site-id/${id}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getTotalByCompetenceTypeForStaffing: async (
    careUnitIds: number[],
    scenario: number,
    careUnitTypeId: number | null
  ) => {
    const payload = {
      careUnitIds,
      scenario,
      careUnitTypeId,
    };

    return await HttpCommonService.postAsync(`api/dashboard/totalsbycomptype`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getDashboardBenchmarkingData: async (
    careUnitIds: number[],
    year: number,
    competenceType: string,
    careLevelId: number | null,
    medSpecId: number | null,
    totalType: string,
    competence: string | null | undefined,
    contactType: string | null | undefined,
    productionTypes: string[],
    scenarioId: number,
    chartType: number,
    prodTypeList: number[]
  ) => {
    if (chartType === 2) {
      const payload = {
        careUnitIds: careUnitIds,
        competenceType: competenceType,
        careLevelId: careLevelId,
        medSpecId: medSpecId,
        year: year,
        totalType: totalType,
        productionTypes: productionTypes,
        scenarioId: scenarioId,
        prodTypeIdList: prodTypeList,
      };

      return await HttpCommonService.postAsync(`api/dashboard/inpatientbenchmarking`, payload)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    } else if (chartType === 1) {
      const payload = {
        careUnitIds: careUnitIds,
        competenceType: competenceType,
        competence: competence,
        contactType: contactType,
        year: year,
        totalType: totalType,
        productionTypes: productionTypes,
        scenarioId: scenarioId,
        prodTypeIdList: prodTypeList,
      };

      return await HttpCommonService.postAsync(`api/dashboard/outpatientbenchmarking`, payload)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    }
  },

  getProductionKpis: async (careUnitIds: number[], year: number, scenario: number, careUnitTypeId: number | null) => {
    const payload = {
      careUnitIds,
      year,
      scenario,
      careUnitTypeId,
    };

    return await HttpCommonService.postAsync(`api/dashboard/production-kpi`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getPlanRealKpis: async (careUnitIds: number[], year: number, scenario: number) => {
    const payload = {
      careUnitIds,
      year,
      scenario,
    };

    return await HttpCommonService.postAsync(`api/dashboard/plan-real-kpi`, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getBudgetForDashboard: async (careUnitIds: number[], scenario: number, careUnitTypeId: number, year: number) => {
    const payload = {
      careUnitIds,
      scenario,
      careUnitTypeId,
      year,
    };

    return await HttpCommonService.postAsync(`api/dashboard/budgetcostactualr12`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getQueueDetails: async (careUnitIds: number[], year: number) => {
    const payload = {
      careUnitIds,
      year,
    };

    return await HttpCommonService.postAsync(`api/dashboard/queue_details`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getWatingListKpis: async (careUnitIds: number[]) => {
    const payload = {
      careUnitIds,
    };

    return await HttpCommonService.postAsync(`api/dashboard/waitinglist_kpi`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default DashboardService;
