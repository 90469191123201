import HotTable, { HotTableClass } from "@handsontable/react";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Button, DataTableAddButton } from "../../../../../components";
import useDetailPlanSettingTableConfig from "./hooks/useDetailPlanSettingTableConfig";
import { PlanningObjectSchema } from "../../../../../interfaces/planning-object/planningObjectSchema";
import WorkCompetence from "../../../../../interfaces/competence/WorkCompetence";
import useGetWorkCompetencesForCareUnit from "../../../../analytics/ActivityAnalysis/hooks/useGetWorkCompetencesForCareUnit";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import usePlanningObjects from "../../../../analytics/ActivityAnalysis/FilterGroup/hooks/usePlanningObjects";
import { OutpatientPlanService } from "../../../../../services";
import DynamicModal from "../../../../../components/DynamicModal/DynamicModal";
import { useTranslation } from "react-i18next";
import { DetailPlanSettingData } from "../../../../../interfaces/production-plan/outpatient/detailedPlan/detailPlanSettingData";
import { DetailPlanSettingDto } from "../../../../../interfaces/production-plan/outpatient/detailedPlan/detailPlanSettingDto";
import { DetailPlanSettingTableData } from "../../../../../interfaces/production-plan/outpatient/detailedPlan/detailPlanSettingTableData";
import useDetailPlanSettingUtilities from "./hooks/useDetailPlanSettingUtilities";
import { toast, ToastContainer } from "react-toastify";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";

interface DetailPlanSettingProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const DetailPlanSetting: React.FC<DetailPlanSettingProps> = ({ isVisible, setIsVisible }) => {
  const { t: translate } = useTranslation();
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const dataTable = useRef<HotTableClass>(null);
  const [detailPlanSettingData, setDetailPlanSettingData] = useState<DetailPlanSettingTableData[]>([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isTableDataEdited, setIsTableDataEdited] = useState(false);

  const [planningObjects, setPlanningObjects] = useState<PlanningObjectSchema[]>([]);
  const [workCompetences, setWorkCompetences] = useState<WorkCompetence[]>([]);
  const [careUnitIds, setCareUnitIds] = useState<number[]>(careUnitId ? [careUnitId] : []);

  const { transformDataToSave, processFetchData } = useDetailPlanSettingUtilities();

  let culture = localStorage.getItem("i18nextLng");

  if (culture === "en") {
    culture = "en-US";
  } else if (culture === "en-US") {
    culture = "en-US";
  } else {
    culture = "sv-SE";
  }

  useEffect(() => {
    if (careUnitId) {
      setCareUnitIds((prev) => {
        if (prev[0] !== careUnitId) {
          return [careUnitId];
        } else {
          return prev;
        }
      });
    }
  }, [careUnitId]);

  const workCompetenceList = useGetWorkCompetencesForCareUnit(careUnitId ? careUnitId : 0);
  const planningObjectList = usePlanningObjects(careUnitIds);

  useEffect(() => {
    setWorkCompetences(workCompetenceList);
  }, [workCompetenceList]);

  useEffect(() => {
    setPlanningObjects(planningObjectList.data);
  }, [planningObjectList]);

  const props = useDetailPlanSettingTableConfig(
    setIsSaveButtonDisabled,
    dataTable,
    planningObjects,
    workCompetences,
    setIsTableDataEdited
  ).getConfigs();

  useEffect(() => {
    if (careUnitId && scenario) {
      OutpatientPlanService.getDetailPlanSetting(careUnitId, scenario).then((res) => {
        if (res.data) {
          const detailPlanSetting: DetailPlanSettingData[] = res.data;
          const transformedData: DetailPlanSettingTableData[] = processFetchData(detailPlanSetting);
          setDetailPlanSettingData(transformedData);
        }
      });
    }
  }, [careUnitId, scenario]);

  const handleSaveButtonClick = () => {
    if (careUnitId) {
      const toastId = toast(
        <NotificationFloat varaint="loading" content={translate("saving_outpatient_parameters")} />,
        {
          icon: false,
          className: "custom-toast",
          containerId: "notificationBox",
          autoClose: false,
        }
      );
      setIsSaveButtonDisabled(true);
      setIsTableDataEdited(false);

      const transformedData: DetailPlanSettingDto[] = transformDataToSave(detailPlanSettingData);

      OutpatientPlanService.manageDetailPlanSetting(careUnitId, scenario, transformedData)
        .then(() => {
          setIsSaveButtonDisabled(true);
          setIsTableDataEdited(false);

          toast.update(toastId, {
            render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((error) => {
          setIsSaveButtonDisabled(false);
          console.error(error);

          toast.update(toastId, {
            render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
            type: "error",
            autoClose: 3000,
          });
        });
    }
  };

  useEffect(() => {
    isTableDataEdited ? setIsSaveButtonDisabled(false) : setIsSaveButtonDisabled(true);
  }, [isTableDataEdited]);

  return (
    <>
      <ToastContainer
        containerId={"notificationBox"}
        style={{ width: "400px" }}
        position="top-right"
        hideProgressBar={true}
        closeButton={false}
      />
      <DynamicModal isOpen={isVisible} onClose={setIsVisible} title="">
        <HotTable data={detailPlanSettingData} {...props} className="pb-72" />
        <DataTableAddButton dataTable={dataTable} />
        <div className="absolute bottom-8 right-4 z-[1000]">
          <Button
            text={translate("save")}
            variant={"primary"}
            additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
            onClick={handleSaveButtonClick}
            disabled={isSaveButtonDisabled}
          />
        </div>
      </DynamicModal>
    </>
  );
};

export default DetailPlanSetting;
