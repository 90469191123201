import { AxiosResponse } from "axios";
import { BenchmarkingChartType } from "../interfaces/productivity/benchmarking-chart-types";
import { SumOfProductionSumForMedicalSpecialityResponse } from "../interfaces/responses";
import { RESULT_ENDPOINT } from "../utils/apiendpoints";
import HttpCommonService from "./httpCommonService";

const ResultService = {
  executeAlgoAsync: async (id: number, type: string, scenarioId: number | null) => {
    return await HttpCommonService.getAsync(`${RESULT_ENDPOINT}/${type}/${id}/${scenarioId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getTotalCost: async (careUnitId: number | null, scenarioId: number) => {
    return await HttpCommonService.getAsync(`api/results/budget/${careUnitId}/${scenarioId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getSankeyChartData: async (careUnitId: number | null, type: string, competenceType: string, scenarioId: number) => {
    return await HttpCommonService.getAsync(
      `api/results/totalsforsankey/${type}/${competenceType}/${careUnitId}/${scenarioId}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getBenchmarkChartData: async (
    productionTypes: string[],
    typeofinput: string,
    comptype: string,
    careproviderid: number,
    carelevelid: string | undefined,
    medspecid: string | undefined,
    chartType: string,
    competence: string | undefined,
    contactType: string | undefined,
    startDate: string | undefined,
    endDate: string | undefined,
    scenarioId: number,
    prodTypeIdList: number[]
  ) => {
    if (chartType === BenchmarkingChartType.Outpatient) {
      const benchmarkEndpoint = "api/results/internal-benchmarking-outpatient";
      const chartDto = {
        productionTypes,
        totalType: typeofinput,
        competenceType: comptype,
        careProviderId: careproviderid,
        competence: (competence !== null || competence !== undefined) && competence !== "" ? competence : null,
        contactType: (contactType !== null || contactType !== undefined) && contactType !== "" ? contactType : null,
        fromDate: startDate,
        toDate: endDate,
        scenarioId: scenarioId,
        prodTypeIdList: prodTypeIdList,
      };

      return await HttpCommonService.postAsync(benchmarkEndpoint, chartDto)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    } else if (chartType === BenchmarkingChartType.Inpatient) {
      const benchmarkEndpoint = "api/results/internal-benchmarking";
      const chartDto = {
        productionTypes,
        totalType: typeofinput,
        competenceType: comptype,
        careProviderId: careproviderid,
        careLevelId: carelevelid === "" ? null : carelevelid,
        medSpecId: medspecid === "" ? null : medspecid,
        fromDate: startDate,
        toDate: endDate,
        scenarioId: scenarioId,
        prodTypeIdList: prodTypeIdList,
      };

      return await HttpCommonService.postAsync(benchmarkEndpoint, chartDto)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    } else if (chartType === BenchmarkingChartType.Emergency) {
      const benchmarkEndpoint = "api/results/internal-benchmarking-emergency";
      const chartDto = {
        productionTypes,
        totalType: typeofinput,
        competenceType: comptype,
        careProviderId: careproviderid,
        fromDate: startDate,
        toDate: endDate,
        scenarioId: scenarioId,
      };

      return await HttpCommonService.postAsync(benchmarkEndpoint, chartDto)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    }
  },

  getAverageOfInpatientProductionOutcomeChartData: async (careUnitId: number | null, year: number | null) => {
    return await HttpCommonService.getAsync(`api/results/averageofinpatientproductionoutcome/${careUnitId}/${year}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getAverageOfInpatientProductionOutcomeByMedicalSpecialitiesChartData: async (
    careUnitId: number | null,
    year: number | null
  ) => {
    return await HttpCommonService.getAsync(
      `api/results/averageofinpatientproductionoutcomebymedicalspecialities/${careUnitId}/${year}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getSumOfInpatientProductionOutcomeChartData: async (careUnitId: number | null, year: number | null) => {
    return await HttpCommonService.getAsync(`api/results/sumofinpatientproductionoutcome/${careUnitId}/${year}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getSumOfInpatientProductionOutcomeForMedicalSpecialityChartData: async (
    careUnitId: number | null,
    year: number | null
  ): Promise<AxiosResponse<SumOfProductionSumForMedicalSpecialityResponse[]>> => {
    return await HttpCommonService.getAsync(
      `api/results/sumofinpatientproductionoutcomebymedicalspeciality/${careUnitId}/${year}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getAverageOfLosPlanChartData: async (careUnitId: number | null) => {
    return await HttpCommonService.getAsync(`api/results/averageoflosplan/${careUnitId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default ResultService;
