import { HandsOnTableColumn } from "../../../../../../interfaces/handsOnTable/handsOnTableColumn";
import WorkCompetence from "../../../../../../interfaces/competence/WorkCompetence";
import { PlanningObjectSchema } from "../../../../../../interfaces/planning-object/planningObjectSchema";
import { useTranslation } from "react-i18next";
import { multiSelectValidator } from "../../../../../../utils/handsontable-configuration/validators/validators";

interface DetailPlanSettingColumnsProps {
  planningObjects: PlanningObjectSchema[];
  workCompetences: WorkCompetence[];
  culture: string;
}

const useDetailPlanSettingTableColumns = ({
  planningObjects,
  workCompetences,
  culture,
}: DetailPlanSettingColumnsProps) => {
  const { t: translate } = useTranslation();

  const cols: HandsOnTableColumn[] = [
    {
      meta: {
        data: "planningObjectId",
        type: "keyValueDropdown",
        source: planningObjects?.map((group) => {
          return {
            _id: group.id,
            label: translate(group.planningObjectName),
          };
        }) as any,
        editorParams: { width: 200 },
      },
      headerTitle: "patient_flow",
      width: 200,
    },
    {
      meta: {
        data: "workCompetenceId",
        type: "multiSelect",
        multiSelectOptions: workCompetences.map((obj) => {
          return {
            value: Number(obj.id),
            label: obj.shortName ? obj.shortName : "",
          };
        }),
        validator: multiSelectValidator,
      },
      headerTitle: "work_competence",
      width: 500,
    },
    {
      meta: {
        data: "timeSpent",
        type: "numeric",
        numericFormat: {
          pattern: {
            mantissa: 1,
          },

          culture,
        },
      },
      headerTitle: "time_spent",
      width: 130,
    },
  ];

  return cols;
};

export default useDetailPlanSettingTableColumns;
