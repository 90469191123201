import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { HideToggle, MultiSelect, Select } from "../../../../components";
import { useTranslation } from "react-i18next";
import { ISelectOption, MultiSelectOption } from "../../../../interfaces";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import OutpatientPlanService from "../../../../services/outpatientPlanService";
import { DetailPlanFilterOptionResponse } from "../../../../interfaces/production-plan/outpatient/detailedPlan/detailed-plan";
import { v4 as uuid } from "uuid";
import PlanningObjectService from "../../../../services/planningObjectService";
import { AxiosResponse } from "axios";
import { PlanningObjectSchema } from "../../../../interfaces/planning-object/planningObjectSchema";
import { PlanningObjectimensions } from "../../../../interfaces/planning-object/planningObjectDimension";
import { OutPatientMultiselectFilterProps } from "../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import usePhysicianSubUnits from "../../../../components/physician-sub-units/hooks/usePhysicianSubUnits";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";

const OutpatientMultiselectFilter = ({
  outPatientMultiSelectRequest,
  setOutPatientMultiSelectRequest,
  careUnitIds,
  selectedTabIndex,
}: OutPatientMultiselectFilterProps) => {
  const { t: translate } = useTranslation();

  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.DetailedPlanMultiSelect);
  const isPlanningObjectToggleFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.OutpatientResultByPlanningObject);

  // available options
  const type_of_visits: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("follow_up_visit"),
      value: "0",
    },
    { id: uuid(), label: translate("new_visit"), value: "1" },
  ];
  const type_of_emergency: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("is_planned"),
      value: "0",
    },
    {
      id: uuid(),
      label: translate("is_emergency"),
      value: "1",
    },
  ];

  const [typesOfVisits, setTypesOfVisit] = useState<MultiSelectOption[]>([]);
  const [typesOfEmergency, setTypesOfEmergency] = useState<MultiSelectOption[]>([]);
  const [contactTypes, setContactTypes] = useState<MultiSelectOption[]>([]);
  const [professions, setProfessions] = useState<MultiSelectOption[]>([]);
  const [contactReasons, setContactReasons] = useState<MultiSelectOption[]>([]);
  const [careTypes, setCareTypes] = useState<MultiSelectOption[]>([]);
  const [medicalSpecialties, setMedicalSpecialties] = useState<MultiSelectOption[]>([]);
  const [filterGroupDropDownList, setFilterGroupDropDownList] = useState<ISelectOption[]>([]);
  const [filterGroupList, setFilterGroupList] = useState<PlanningObjectSchema[]>([]);
  const [isFilterByPlnningObject, setIsFilterByPlnningObject] = useState<boolean>(false);

  // selected options
  const [selectedVisit, setSelectedVisit] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedTypeOfEmergency, setSelectedTypeOfEmergency] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedContactType, setSelectedContactType] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedProfession, setSelectedProfession] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedContactReason, setSelectedContactReason] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedCareType, setSelectedCareType] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedMedicalSpecialties, setSelectedMedicalSpecialties] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedFilterGroup, setSelectedFilterGroup] = useState<ISelectOption>({
    value: "null",
    label: translate("all"),
  });

  const { physicanSubUnits } = usePhysicianSubUnits();

  useEffect(() => {
    const mapSelectedValues = (selectedValues: MultiSelectOption[]) =>
      selectedValues.filter((x) => x.value !== "null").map((x) => parseInt(x.value.toString(), 10));

    if (
      selectedVisit.length === typesOfVisits.length - 1 ||
      selectedTypeOfEmergency.length === typesOfEmergency.length - 1 ||
      selectedProfession.length === professions.length - 1 ||
      selectedContactReason.length === contactReasons.length - 1 ||
      selectedCareType.length === careTypes.length - 1 ||
      selectedContactType.length === contactTypes.length - 1 ||
      selectedMedicalSpecialties.length === medicalSpecialties.length - 1
    ) {
      selectedVisit.length === typesOfVisits.length - 1 ? setSelectedVisit(typesOfVisits) : selectedVisit;
      selectedTypeOfEmergency.length === typesOfEmergency.length - 1
        ? setSelectedTypeOfEmergency(typesOfEmergency)
        : selectedTypeOfEmergency;
      selectedProfession.length === professions.length - 1 ? setSelectedProfession(professions) : selectedProfession;
      selectedContactReason.length === contactReasons.length - 1
        ? setSelectedContactReason(contactReasons)
        : selectedContactReason;
      selectedCareType.length === careTypes.length - 1 ? setSelectedCareType(careTypes) : selectedCareType;
      selectedContactType.length === contactTypes.length - 1
        ? setSelectedContactType(contactTypes)
        : selectedContactType;
      selectedMedicalSpecialties.length === medicalSpecialties.length - 1
        ? setSelectedMedicalSpecialties(medicalSpecialties)
        : selectedMedicalSpecialties;
    } else {
      if (
        selectedVisit.length > 0 &&
        selectedTypeOfEmergency.length > 0 &&
        selectedProfession.length > 0 &&
        selectedContactReason.length > 0 &&
        selectedCareType.length > 0 &&
        selectedContactType.length > 0 &&
        selectedMedicalSpecialties.length > 0
      ) {
        setOutPatientMultiSelectRequest({
          ...outPatientMultiSelectRequest,
          contactTypeIds:
            isEqual(selectedContactType, contactTypes) || selectedContactType.length === 0
              ? [-1]
              : mapSelectedValues(selectedContactType),
          typesOfVisit:
            isEqual(selectedVisit, typesOfVisits) || selectedVisit.length === 0
              ? [-1]
              : mapSelectedValues(selectedVisit),
          typesOfEmergency: isEqual(selectedTypeOfEmergency, typesOfEmergency)
            ? [-1]
            : mapSelectedValues(selectedTypeOfEmergency),
          professionIds: isEqual(selectedProfession, professions) ? [-1] : mapSelectedValues(selectedProfession),
          contactReasonGroupingIds: isEqual(selectedContactReason, contactReasons)
            ? [-1]
            : mapSelectedValues(selectedContactReason),
          careTypeIds:
            isEqual(selectedCareType, careTypes) || selectedCareType.length === 0
              ? [-1]
              : mapSelectedValues(selectedCareType),
          medicalSpecialtyIds: isEqual(selectedMedicalSpecialties, medicalSpecialties)
            ? [-1]
            : mapSelectedValues(selectedMedicalSpecialties),
          isPlanningObjectFilterEnabled : isFilterByPlnningObject
        });
      }
    }
  }, [
    selectedVisit,
    selectedTypeOfEmergency,
    selectedProfession,
    selectedContactReason,
    selectedCareType,
    selectedContactType,
    selectedMedicalSpecialties,
    isFilterByPlnningObject
  ]);

  const getDimensionsForProdVsPlan = async (careUnitIdList: number[]) => {
    if (careUnitIdList) {
      await OutpatientPlanService.getDimensionsForFilters(careUnitIdList).then((res) => {
        if (res != null) {
          const defaultFirstOption: MultiSelectOption = {
            id: uuid(),
            value: "null",
            label: translate("all"),
          };
          const professions: MultiSelectOption[] = [];
          const contactTypes: MultiSelectOption[] = [];
          const contactReasons: MultiSelectOption[] = [];
          const careTypes: MultiSelectOption[] = [];
          const medicalSpecs: MultiSelectOption[] = [];
          const typesOfVisit: MultiSelectOption[] = [];
          const typesOfEmergency: MultiSelectOption[] = [];

          professions.length === 0 && professions.push(defaultFirstOption);
          contactTypes.length === 0 && contactTypes.push(defaultFirstOption);
          contactReasons.length === 0 && contactReasons.push(defaultFirstOption);
          careTypes.length === 0 && careTypes.push(defaultFirstOption);
          medicalSpecs.length === 0 && medicalSpecs.push(defaultFirstOption);
          typesOfVisit.length === 0 && typesOfVisit.push(defaultFirstOption);
          typesOfEmergency.length === 0 && typesOfEmergency.push(defaultFirstOption);

          res?.contactTypes?.map((obj: DetailPlanFilterOptionResponse) => {
            contactTypes.push({
              id: uuid(),
              value: obj.id.toString(),
              label: obj.name,
            });
          });

          res?.professions?.map((obj: DetailPlanFilterOptionResponse) => {
            professions.push({
              id: uuid(),
              value: obj.id.toString(),
              label: obj.name,
            });
          });

          res?.contactReasonGroupings?.map((obj: DetailPlanFilterOptionResponse) => {
            contactReasons.push({
              id: uuid(),
              value: obj.id.toString(),
              label: obj.name,
            });
          });

          res?.careTypes?.map((obj: DetailPlanFilterOptionResponse) => {
            careTypes.push({
              id: uuid(),
              value: obj.id.toString(),
              label: obj.name,
            });
          });

          res?.medicalSpecialties?.map((obj: DetailPlanFilterOptionResponse) => {
            medicalSpecs.push({
              id: uuid(),
              value: obj.id.toString(),
              label: obj.name,
            });
          });

          type_of_visits.map((obj: MultiSelectOption) => {
            typesOfVisit.push({
              id: obj.id,
              value: obj.value,
              label: obj.label,
            });
          });

          type_of_emergency.map((obj: MultiSelectOption) => {
            typesOfEmergency.push({
              id: obj.id,
              value: obj.value,
              label: obj.label,
            });
          });

          setProfessions(professions);
          setContactTypes(contactTypes);
          setContactReasons(contactReasons);
          setCareTypes(careTypes);
          setMedicalSpecialties(medicalSpecs);
          setTypesOfVisit(typesOfVisit);
          setTypesOfEmergency(typesOfEmergency);

          setSelectedContactType(contactTypes);
          setSelectedContactReason(contactReasons);
          setSelectedProfession(professions);
          setSelectedCareType(careTypes);
          setSelectedMedicalSpecialties(medicalSpecs);
          setSelectedVisit(typesOfVisit);
          setSelectedTypeOfEmergency(typesOfEmergency);
        }
      });
    }
  };

  const handlePlanningObjectToggle = () => {
    setIsFilterByPlnningObject((prevState) => !prevState);
  };

  const getFilterGroups = async (careUnitIdList: number[]) => {
    if (careUnitIdList) {
      await PlanningObjectService.getPlanningObjects(careUnitIdList).then(
        (res: AxiosResponse<PlanningObjectSchema[]>) => {
          const response = res.data as PlanningObjectSchema[];
          setFilterGroupList(response);
        }
      );
    }
  };

  useEffect(() => {
    const defaultFirstOption: ISelectOption = {
      value: "null",
      label: translate("all"),
    };

    setSelectedFilterGroup(defaultFirstOption);

    const filterGroups: ISelectOption[] = [];
    filterGroups.push(defaultFirstOption);

    filterGroupList.map((obj: PlanningObjectSchema) => {
      filterGroups.push({
        value: obj.id.toString(),
        label: translate(obj.planningObjectName),
      });
    });

    setFilterGroupDropDownList(filterGroups);
  }, [filterGroupList]);

  useEffect(() => {
    if (
      careUnitIds !== undefined &&
      careUnitIds !== null &&
      careUnitTypeId != null &&
      (careUnitTypeId == 1 || careUnitTypeId == 3)
    ) {
      getDimensionsForProdVsPlan(careUnitIds);
      getFilterGroups(careUnitIds);
    } else if (
      careUnitIds == null &&
      careUnitId !== null &&
      careUnitTypeId != null &&
      (careUnitTypeId == 1 || careUnitTypeId == 3)
    ) {
      const careUnitList: number[] = [careUnitId];
      getDimensionsForProdVsPlan(careUnitList);
      getFilterGroups(careUnitList);
    }
  }, [careUnitIds, careUnitTypeId, careUnitId]);

  const handleTypeOfVisitSelect = (option: MultiSelectOption) => {
    let selectedTypeOfVisistsFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedVisit(typesOfVisits);
    } else {
      const isSelectedOptionExists = selectedVisit.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedTypeOfVisistsFromSelect = selectedVisit.filter((x) => x.value !== "null");
        selectedTypeOfVisistsFromSelect = [...selectedTypeOfVisistsFromSelect, option];
        setSelectedVisit(selectedTypeOfVisistsFromSelect);
      }
    }
  };

  const handleTypeOfVisitDeselect = (option: MultiSelectOption) => {
    if (selectedVisit.length <= typesOfVisits.length && selectedVisit.length > 1) {
      if (option.value === "null") {
        setSelectedVisit([]);
      } else {
        const isDeselectedOptionExists =
          selectedVisit.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedVisit(() => {
            return selectedVisit.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleTypeOfEmergencySelect = (option: MultiSelectOption) => {
    let selectedTypesOfEmergencyFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedTypeOfEmergency(typesOfEmergency);
    } else {
      const isSelectedOptionExists =
        selectedTypeOfEmergency.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedTypesOfEmergencyFromSelect = selectedTypeOfEmergency.filter((x) => x.value !== "null");
        selectedTypesOfEmergencyFromSelect = [...selectedTypesOfEmergencyFromSelect, option];
        setSelectedTypeOfEmergency(selectedTypesOfEmergencyFromSelect);
      }
    }
  };

  const handleTypeOfEmergencyDeselect = (option: MultiSelectOption) => {
    if (selectedTypeOfEmergency.length <= typesOfEmergency.length && selectedTypeOfEmergency.length > 1) {
      if (option.value === "null") {
        setSelectedTypeOfEmergency([]);
      } else {
        const isDeselectedOptionExists =
          selectedTypeOfEmergency.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedTypeOfEmergency(() => {
            return selectedTypeOfEmergency.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleContactTypeSelect = (option: MultiSelectOption) => {
    let selectedcontactTypeFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedContactType(contactTypes);
    } else {
      const isSelectedOptionExists =
        selectedContactType.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedcontactTypeFromSelect = selectedContactType.filter((x) => x.value !== "null");
        selectedcontactTypeFromSelect = [...selectedcontactTypeFromSelect, option];
        setSelectedContactType(selectedcontactTypeFromSelect);
      }
    }
  };

  const handleContactTypeDeselect = (option: MultiSelectOption) => {
    if (selectedContactType.length <= contactTypes.length && selectedContactType.length > 1) {
      if (option.value === "null") {
        setSelectedContactType([]);
      } else {
        const isDeselectedOptionExists =
          selectedContactType.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedContactType(() => {
            return selectedContactType.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleProfessionSelect = (option: MultiSelectOption) => {
    let selectedProfessionsSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedProfession(professions);
    } else {
      const isSelectedOptionExists =
        selectedProfession.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedProfessionsSelect = selectedProfession.filter((x) => x.value !== "null");
        selectedProfessionsSelect = [...selectedProfessionsSelect, option];
        setSelectedProfession(selectedProfessionsSelect);
      }
    }
  };

  const handleProfessionDeselect = (option: MultiSelectOption) => {
    if (selectedProfession.length <= professions.length && selectedProfession.length > 1) {
      if (option.value === "null") {
        setSelectedProfession([]);
      } else {
        const isDeselectedOptionExists =
          selectedProfession.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedProfession(() => {
            return selectedProfession.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleContactReasonSelect = (option: MultiSelectOption) => {
    let selectedContactReasonSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedContactReason(contactReasons);
    } else {
      const isSelectedOptionExists =
        selectedContactReason.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedContactReasonSelect = selectedContactReason.filter((x) => x.value !== "null");
        selectedContactReasonSelect = [...selectedContactReasonSelect, option];
        setSelectedContactReason(selectedContactReasonSelect);
      }
    }
  };

  const handleContactReasonDeselect = (option: MultiSelectOption) => {
    if (selectedContactReason.length <= contactReasons.length && selectedContactReason.length > 1) {
      if (option.value === "null") {
        setSelectedContactReason([]);
      } else {
        const isDeselectedOptionExists =
          selectedContactReason.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedContactReason(() => {
            return selectedContactReason.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleCareTypeSelect = (option: MultiSelectOption) => {
    let selectedCareTypeSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedCareType(careTypes);
    } else {
      const isSelectedOptionExists = selectedCareType.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedCareTypeSelect = selectedCareType.filter((x) => x.value !== "null");
        selectedCareTypeSelect = [...selectedCareTypeSelect, option];
        setSelectedCareType(selectedCareTypeSelect);
      }
    }
  };

  const handleCareTypeDeselect = (option: MultiSelectOption) => {
    if (selectedCareType.length <= careTypes.length && selectedCareType.length > 1) {
      if (option.value === "null") {
        setSelectedCareType([]);
      } else {
        const isDeselectedOptionExists =
          selectedCareType.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedCareType(() => {
            return selectedCareType.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleMedicalSpecialtiesSelect = (option: MultiSelectOption) => {
    let selectedMedicalSpecialtiesSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedMedicalSpecialties(medicalSpecialties);
    } else {
      const isSelectedOptionExists =
        selectedMedicalSpecialties.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedMedicalSpecialtiesSelect = selectedMedicalSpecialties.filter((x) => x.value !== "null");
        selectedMedicalSpecialtiesSelect = [...selectedMedicalSpecialtiesSelect, option];
        setSelectedMedicalSpecialties(selectedMedicalSpecialtiesSelect);
      }
    }
  };

  const handleMedicalSpecialtiesDeselect = (option: MultiSelectOption) => {
    if (selectedMedicalSpecialties.length <= medicalSpecialties.length && selectedMedicalSpecialties.length > 1) {
      if (option.value === "null") {
        setSelectedMedicalSpecialties([]);
      } else {
        const isDeselectedOptionExists =
          selectedMedicalSpecialties.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedMedicalSpecialties(() => {
            return selectedMedicalSpecialties.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const getFilterGroupDimensions = async (selectedFilterGroupId: number) => {
    await PlanningObjectService.getDimensionsForPlanningObject(selectedFilterGroupId).then(
      (res: AxiosResponse<PlanningObjectimensions>) => {
        const response = res.data as PlanningObjectimensions;

        const professionIds = professions.filter((r) => response.professionIds.includes(Number(r.value.toString())));
        setSelectedProfession(professionIds);

        const careTypeIds = careTypes.filter((r) => response.careTypeIds.includes(Number(r.value.toString())));
        setSelectedCareType(careTypeIds);

        const contactTypeIds = contactTypes.filter((r) => response.contactTypeIds.includes(Number(r.value.toString())));
        setSelectedContactType(contactTypeIds);

        const contactReasonGroupingIds = contactReasons.filter((r) =>
          response.contactReasonGroupingIds.includes(Number(r.value.toString()))
        );
        setSelectedContactReason(contactReasonGroupingIds);

        const isNewVisits = typesOfVisits.filter((r) => response.isNewVisits.includes(Number(r.value.toString())));
        setSelectedVisit(isNewVisits);

        const isEmergencies = typesOfEmergency.filter((r) =>
          response.isEmergencies.includes(Number(r.value.toString()))
        );
        setSelectedTypeOfEmergency(isEmergencies);

        const medicalSpecialtyIds = medicalSpecialties.filter((r) =>
          response.medicalSpecialtyIds.includes(Number(r.value.toString()))
        );
        setSelectedMedicalSpecialties(medicalSpecialtyIds);
      }
    );
  };

  const handleFilterGroupSelect = (selectedFilterGroup: ISelectOption) => {
    setSelectedFilterGroup(selectedFilterGroup);
  };

  useEffect(() => {
    if (selectedFilterGroup.value !== "null") {
      const selectedFilterGroupId = Number(selectedFilterGroup.value);
      getFilterGroupDimensions(selectedFilterGroupId);
    } else {
      setSelectedProfession(professions);
      setSelectedCareType(careTypes);
      setSelectedContactType(contactTypes);
      setSelectedContactReason(contactReasons);
      setSelectedVisit(typesOfVisits);
      setSelectedTypeOfEmergency(typesOfEmergency);
      setSelectedMedicalSpecialties(medicalSpecialties);
    }
  }, [selectedFilterGroup]);

  return (
    <>
      <div className="mr-0">
        <div className="flex items-center">
          <div className="w-full pb-2">
            <MultiSelect
              label={translate("type_of_visit")}
              selectedOptions={selectedVisit}
              options={typesOfVisits}
              onSelectItem={handleTypeOfVisitSelect}
              onRemoveSelectItem={handleTypeOfVisitDeselect}
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-full pb-2">
            <MultiSelect
              label={translate("contact_type")}
              selectedOptions={selectedContactType}
              options={contactTypes}
              onSelectItem={handleContactTypeSelect}
              onRemoveSelectItem={handleContactTypeDeselect}
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-full pb-2">
            <MultiSelect
              label={translate("profession")}
              selectedOptions={selectedProfession}
              options={professions}
              onSelectItem={handleProfessionSelect}
              onRemoveSelectItem={handleProfessionDeselect}
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-full pb-2">
            <MultiSelect
              label={translate("contact_reason")}
              selectedOptions={selectedContactReason}
              options={contactReasons}
              onSelectItem={handleContactReasonSelect}
              onRemoveSelectItem={handleContactReasonDeselect}
            />
          </div>
        </div>

        <>
          <div className="flex items-center">
            <div className="w-full pb-2">
              <MultiSelect
                label={translate("care_type")}
                selectedOptions={selectedCareType}
                options={careTypes}
                onSelectItem={handleCareTypeSelect}
                onRemoveSelectItem={handleCareTypeDeselect}
              />
            </div>
          </div>

          {(isFeatureFlagEnabled && selectedTabIndex !== 3) || (!isFeatureFlagEnabled && selectedTabIndex !== 2) ? (
            <div className="flex items-center">
              <div className="w-full pb-2">
                <MultiSelect
                  label={translate("type_of_emergency")}
                  selectedOptions={selectedTypeOfEmergency}
                  options={typesOfEmergency}
                  onSelectItem={handleTypeOfEmergencySelect}
                  onRemoveSelectItem={handleTypeOfEmergencyDeselect}
                />
              </div>
            </div>
          ) : null}
          <div className="flex items-center">
            <div className="w-full pb-2">
              <MultiSelect
                label={translate("medical_speciality")}
                selectedOptions={selectedMedicalSpecialties}
                options={medicalSpecialties}
                onSelectItem={handleMedicalSpecialtiesSelect}
                onRemoveSelectItem={handleMedicalSpecialtiesDeselect}
              />
            </div>
          </div>

          {((careUnitIds !== undefined && careUnitIds.length <= 1) || careUnitId !== null) &&
          physicanSubUnits.length <= 1 &&
          ((isFeatureFlagEnabled && selectedTabIndex !== 3) || (!isFeatureFlagEnabled && selectedTabIndex !== 2)) ? (
            <div className="mt-2 flex items-center">
              <div className={`${isPlanningObjectToggleFeatureFlagEnabled ? "w-5/6" : "w-full"}`}>
                <Select
                  placeholder={translate("patient_flow")}
                  options={filterGroupDropDownList}
                  onSelectOption={handleFilterGroupSelect}
                  selectedOption={selectedFilterGroup}
                />
              </div>
              {isPlanningObjectToggleFeatureFlagEnabled ? (
                <div className="w-1/6">
                  <HideToggle
                    onToggle={handlePlanningObjectToggle}
                    isVisible={outPatientMultiSelectRequest?.isPlanningObjectFilterEnabled ?? false}
                  />
                </div>
                ) : null}
            </div>
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};

export default OutpatientMultiselectFilter;
