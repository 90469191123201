import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { HotTableClass } from "@handsontable/react";
import WorkCompetence from "../../../../../../interfaces/competence/WorkCompetence";
import { PlanningObjectSchema } from "../../../../../../interfaces/planning-object/planningObjectSchema";
import useDetailPlanSettingTableColumns from "./useDetailPlanSettingTableColumns";
import { DataTableProps } from "../../../../../../interfaces/props/data-table-props";

const useDetailPlanSettingTableConfig = (
  setIsSaveButtonDisabled: Dispatch<SetStateAction<boolean>>,
  dataTable: React.RefObject<HotTableClass>,
  planningObjects: PlanningObjectSchema[],
  workCompetences: WorkCompetence[],
  setIsTableDataEdited: Dispatch<SetStateAction<boolean>>
) => {
  let culture = localStorage.getItem("i18nextLng");

  if (culture === "en") {
    culture = "en-US";
  } else if (culture === "en-US") {
    culture = "en-US";
  } else {
    culture = "sv-SE";
  }

  const { t: translate } = useTranslation();

  const columns = useDetailPlanSettingTableColumns({
    planningObjects: planningObjects,
    workCompetences: workCompetences,
    culture,
  });

  const defaultData = {
    planningObjectId: 0,
    planningObjectName: "",
    workCompetenceId: "",
    timeSpent: 0,
  };

  const getConfigs = (): DataTableProps => {
    const props: DataTableProps = {
      ref: dataTable,
      afterGetColHeader: (col, TH) => {
        TH.classList.add("dark-blue-column");
        TH.classList.add("htMiddle");
      },
      afterRemoveRow: () => {
        setIsSaveButtonDisabled(false);
      },
      afterChange: (changes, source) => {
        if (source === "edit" || source === "Autofill.fill" || source === "CopyPaste.paste") {
          dataTable.current?.hotInstance?.validateCells((valid) => {
            if (valid) {
              setIsSaveButtonDisabled(false);
              setIsTableDataEdited(true);
            }
          });
        }
      },
      cells: () => {
        type cp = {
          className?: string;
          readOnly?: boolean;
          editor?: boolean | string;
        };

        const cp: cp = {};

        cp.className = "htMiddle";

        return cp;
      },
      colHeaders: columns.map((c) => translate(c.headerTitle).replace("\n", "<br />")),
      columns: columns.map((c) => c.meta),
      columnSorting: true,
      colWidths: columns.map((c) => c.width),
      contextMenu: ["row_above", "remove_row", "row_below", "alignment", "copy", "cut"],
      dataSchema: defaultData,
      dropdownMenu: ["filter_by_condition", "filter_action_bar", "filter_by_value"],
      licenseKey: "non-commercial-and-evaluation",
      language: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
      numericFormat: {
        culture: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
        pattern: {},
      },
      rowHeaders: true,
      rowHeaderWidth: 30,
      rowHeights: 35,
      wordWrap: false,
      height: "auto",
    };

    return props;
  };

  return { getConfigs };
};

export default useDetailPlanSettingTableConfig;
