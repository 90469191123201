import Core from "handsontable/core";
import { autocompleteRenderer } from "handsontable/renderers";
import { CellProperties } from "handsontable/settings";

export const multiSelectRenderer = function (
  hot: Core,
  TD: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: CellProperties
) {
  TD.innerHTML = "<div class='htAutocompleteArrow htMiddle'>▼</div>";

  const selectedValues = Array.isArray(value) ? value[0].split(",") : value.split(",");

  const selectedLabels = selectedValues
    .map((val: string) => {
      const trimmedVal = val.trim();
      const item = cellProperties?.multiSelectOptions?.find(
        (item: { label: string; value: number }) => item.value === Number(trimmedVal)
      );
      return item ? item.label : trimmedVal;
    })
    .filter(Boolean)
    .join(", ");

  autocompleteRenderer.call(hot, hot, TD, row, col, prop, selectedLabels, cellProperties);
};
