import { DetailPlanSettingData } from "../../../../../../interfaces/production-plan/outpatient/detailedPlan/detailPlanSettingData";
import { DetailPlanSettingTableData } from "../../../../../../interfaces/production-plan/outpatient/detailedPlan/detailPlanSettingTableData";
import { DetailPlanSettingDto } from "../../../../../../interfaces/production-plan/outpatient/detailedPlan/detailPlanSettingDto";

const useDetailPlanSettingUtilities = () => {
  const processFetchData = (data: DetailPlanSettingData[]): DetailPlanSettingTableData[] => {
    const groupedMap = data.reduce((acc, curr) => {
      const key = curr.planningObjectId;

      if (!acc.has(key)) {
        acc.set(key, {
          planningObjectId: curr.planningObjectId,
          planningObjectName: curr.planningObjectName,
          workCompetenceId: new Set<number>([curr.workCompetenceId]),
          timeSpent: curr.timeSpent,
        });
      } else {
        const existing = acc.get(key)!;
        existing.workCompetenceId.add(curr.workCompetenceId);
        existing.timeSpent = curr.timeSpent;
      }

      return acc;
    }, new Map<number, Omit<DetailPlanSettingTableData, "workCompetenceId"> & { workCompetenceId: Set<number> }>());

    return Array.from(groupedMap.values()).map((item) => ({
      ...item,
      workCompetenceId: Array.from(item.workCompetenceId).join(","),
    }));
  };

  const transformDataToSave = (data: DetailPlanSettingTableData[]) => {
    const detailPlanSetting: DetailPlanSettingDto[] = data.map((x) => {
      return {
        planningObjectId: x.planningObjectId,
        workCompetenceId: x.workCompetenceId.split(",").map(Number),
        timeSpent: x.timeSpent,
      };
    });

    return detailPlanSetting;
  };

  return { transformDataToSave, processFetchData };
};

export default useDetailPlanSettingUtilities;
