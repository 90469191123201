import React from "react";
import HotTable from "@handsontable/react";
import { useTranslation } from "react-i18next";

import { ControlIconTypes } from "../../../../components/control-icon/icon-types";
import ControlIcon from "../../../../components/control-icon/control-icon";
import { Button } from "../../../../components";
import useGetActivityResultsData from "./hooks/useGetActivityResultsData";
import useActivityAnalysisResultConfigs from "./hooks/useActivityAnalysisResultConfig";
import { ActivityAnalysisResultsProps } from "../../../../interfaces/props/activity-analysis-results";
import "./styles/activity-analysis-result.css";
import useGetProductionTypeData from "../../../../common/hooks/data/useGetProductionTypeData";

const ActivityAnalysisResults = ({
  careUnitId,
  year,
  scenarioId,
  onPrevButtonClick,
  careUnitTypeId,
}: ActivityAnalysisResultsProps) => {
  const hook = useGetActivityResultsData(careUnitId, scenarioId, year);
  const productionTypes = useGetProductionTypeData(careUnitTypeId);
  const configs = useActivityAnalysisResultConfigs(hook.data, productionTypes.data);

  const { t: translate } = useTranslation();

  const handlePrevButtonClick = () => {
    onPrevButtonClick();
  };

  return (
    <div className="col-start-1 col-end-13">
      <div className="rounded px-2">
        <div className="relative h-[79vh] ">
          <div className="h-[65vh] overflow-y-scroll">
            <HotTable data={hook.data} {...configs.props}></HotTable>
          </div>
          <div className="mt-4 flex w-full items-center justify-end gap-4 pr-4">
            <div className="flex items-center gap-4">
              <Button
                onClick={handlePrevButtonClick}
                text={translate("prev")}
                variant={"button--secondary-optional"}
                additionalStyles={"xl:w-[10.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[8.313rem]"}
                icon={<ControlIcon type={ControlIconTypes.perv} />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityAnalysisResults;
