import React from "react";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";

const EDAnalytics = () => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.Metabase);

  return (
    <>
      {isFeatureFlagEnabled ? (
        <iframe
          title="Emergency Department Dashboard"
          className="h-[90vh] w-[95vw] border-none"
          src="https://app.powerbi.com/reportEmbed?reportId=dc6572a9-b7f6-432a-8ee6-135672a72048&autoAuth=true&embeddedDemo=true"
          allowFullScreen={true}
        ></iframe>
      ) : null}
    </>
  );
};

export default EDAnalytics;
